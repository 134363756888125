import {GridColDef} from "@mui/x-data-grid";
import {FileRead, FileType} from "../../../../../declerations/server/files_models";
import dayjs, {Dayjs} from "dayjs";
import {SHORT_DATE_FORMAT} from "../../components/dialogs/common";
import React, {ReactNode} from "react";
import {FileName} from "./FileName";
import InputUtils from "../../../../../helpers/InputUtils";
import {map} from "lodash";
import {Text} from "../../../../../bounce-ui/backoffice";

interface RowData {
  id: string;
  name: ReactNode;
  category: FileType;
  uploadTime: string | Dayjs;
  fileDate?: string;
  description?: string;
}

const ExcludedFileTypes: FileType[] = [
  "email_sent",
  "phone_call_recording",
  "phone_call_transcription",
  "email_received",
  "letter_sent",
  "sms_sent",
];

const sortDatesDesc = (date1: string, date2: string) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d1 > d2) return -1;
  if (d1 < d2) return 1;
  return 0;
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 0.8,
    renderCell: (params) => {
      return <FileName fileName={params.row.name} fileId={params.row.id} />;
    },
  },
  {
    field: "category",
    headerName: "Category",
    flex: 0.3,
    renderCell: (params) => {
      return InputUtils.prettierSnakeCase(params.row.category);
    },
  },
  {
    field: "fileDate",
    headerName: "File Date",
    flex: 0.3,
    sortComparator: sortDatesDesc,
  },
  {
    field: "uploadTime",
    headerName: "Upload Time",
    flex: 0.3,
    sortComparator: sortDatesDesc,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => {
      return (
        <Text size={14} sx={{overflow: "visible", whiteSpace: "normal"}}>
          {params.row.description}
        </Text>
      );
    },
  },
];

const prettifyJson = (data: string) => {
  const dataJson = JSON.parse(data);
  delete dataJson.file_date;
  const formattedOutput = map(dataJson, (value, key) => `${InputUtils.prettierSnakeCase(key)}: ${value}`);
  return formattedOutput.join("\n");
};

const convertFileToRowData = (files: FileRead[]): RowData[] | undefined => {
  return files.map((file: FileRead, index) => {
    const fileDate = file.file_metadata["file_date"];
    const description = prettifyJson(JSON.stringify(file.file_metadata));
    return {
      id: file.id,
      name: file.filename,
      category: file.type,
      uploadTime: dayjs(file.created_at).format(SHORT_DATE_FORMAT),
      fileDate: fileDate ? dayjs(fileDate).format(SHORT_DATE_FORMAT) : undefined,
      description: description ?? undefined,
    };
  });
};

export {ExcludedFileTypes, columns, convertFileToRowData};
