import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {UserExternal} from "../../../declerations/server/user_models";
import {useUser} from "../../../contexts";

type Variables = {
  userId?: string;
  accountId?: string;
  isAgent?: boolean;
};

type Data = UserExternal;
export const getUserWithSelectedAccount = async (variables: Variables): Promise<Data> => {
  if (!variables.userId || !variables.isAgent) {
    // @ts-ignore
    return;
  }
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(
    `/backoffice/get_user_details/${variables.userId}`,
    {
      params: {...(variables.accountId && {account_id: variables.accountId})},
    }
  );
  result.data.id = String(result.data.id);
  result.data.account.id = String(result.data.account.id);
  if (result.data.account.active_payment_plan) {
    result.data.account.active_payment_plan.payment_plan_id = String(
      result.data.account.active_payment_plan.payment_plan_id
    );
  }
  if (result.data.accounts_switcher) {
    const accountsSwitcher = result.data.accounts_switcher;
    accountsSwitcher.forEach((account) => {
      account.account_id = String(account.account_id);
    });
    result.data.accounts_switcher = accountsSwitcher;
  }
  return result.data;
};

export const useGetUserWithSelectedAccountQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  const queryKey = variables.userId ? ["user", "data", variables.userId] : ["invalid-user"];
  return useBaseQuery<Data, Variables>(queryKey, getUserWithSelectedAccount, {...variables, isAgent}, options);
};
