import React from "react";
import {Container, IconEdit} from "bounce-ui/backoffice";
import {formatCurrency} from "helpers/formatNumber";
import {Dayjs} from "dayjs";
import {SummaryText} from "../../../../../account/payment-plans-v2/PaymentPlanComponents/utils";
import {formatDate} from "../../../../../account/payment-plans-v2/utils";

interface IOneTimeSummaryDetails {
  last_4_digits?: string;
  payment_method_type?: string;
  installmentSize: number;
  startDate: Dayjs;
  onClickEdit?: () => void;
}

const OneTimeSummary = ({
  last_4_digits,
  payment_method_type,
  installmentSize,
  startDate,
  onClickEdit,
}: IOneTimeSummaryDetails) => {
  return (
    <Container fullWidth sx={{gap: "3px", padding: "10px"}}>
      {last_4_digits && payment_method_type && <SummaryText field={payment_method_type} value={last_4_digits} />}
      <SummaryText field={"installmentAmount"} value={`${formatCurrency(installmentSize)}`} />
      {onClickEdit ? (
        <SummaryText field={"startDate"} value={formatDate(startDate)} icon={<IconEdit />} onClickIcon={onClickEdit} />
      ) : (
        <SummaryText field={"startDate"} value={formatDate(startDate)} />
      )}
    </Container>
  );
};

export default OneTimeSummary;
