import React, {FunctionComponent, useEffect} from "react";
import {Dialog} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import ContactUsContent from "./ContactUsContent";
import {CloseDialogButton, Container, Text} from "../../bounce-ui/consumer";
import {useAmplitude} from "../../contexts";
import {Pages} from "../../bi-events/enum";

interface ContactUsDialogProps extends NiceModalHocProps {
  fetchContact: boolean;
}

const ContactUsDialog: FunctionComponent<ContactUsDialogProps> = NiceModal.create(({fetchContact = true, ...props}) => {
  const {pageViewEvent} = useAmplitude();
  const modal = useModal();

  const closeHandler = (): void => {
    modal.resolve(true);
    modal.hide();
  };

  useEffect(() => {
    pageViewEvent({page: Pages.GET_HELP});
  }, []);

  return (
    <Dialog
      open={modal.visible}
      onClose={closeHandler}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <CloseDialogButton onClose={closeHandler} />
      <Container sx={{gap: "20px", overflow: "auto"}}>
        <Container sx={{alignItems: "start"}}>
          <Text translateId={"howCanWeHelp"} size={22} fontWeight={"bold"} />
          <Text translateId={"sendUsMessage"} />
        </Container>
        <ContactUsContent onClose={closeHandler} fetchContact={fetchContact} />
      </Container>
    </Dialog>
  );
});

export default ContactUsDialog;
