import {IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IconCloseDrawer} from "./index";

interface ICloseDialogButton {
  onClose: () => void;
  isDrawer?: boolean;
}

export const CloseDialogButton = ({onClose, isDrawer = false}: ICloseDialogButton) => {
  const [dialogPosition, setDialogPosition] = useState({top: 0, right: 0});

  useEffect(() => {
    const updatePosition = () => {
      const dialog = document.querySelector(".MuiDialog-paper");
      if (dialog) {
        const rect = dialog.getBoundingClientRect();
        const top = Math.max(0, rect.top);
        const right = Math.max(0, window.innerWidth - rect.right);
        setDialogPosition({top, right});
      }
    };

    const resizeObserver = new ResizeObserver(updatePosition); // Observes changes in the dialog's size
    const dialog = document.querySelector(".MuiDialog-paper"); // Adjust this selector if needed

    if (dialog) {
      resizeObserver.observe(dialog);
      updatePosition(); // Update position initially
    }

    window.addEventListener("scroll", updatePosition);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return (
    <IconButton
      aria-label="close"
      size="small"
      onClick={onClose}
      sx={{
        position: isDrawer ? "absolute" : "fixed",
        top: isDrawer ? (theme) => theme.spacing(1) : dialogPosition.top,
        right: isDrawer ? (theme) => theme.spacing(1) : dialogPosition.right,
        zIndex: 10,
      }}
    >
      <IconCloseDrawer style={{width: "28px", height: "28px"}} />
    </IconButton>
  );
};
