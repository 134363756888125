import {ConfigState} from "../store/definitions";
import {merge} from "lodash";
import {sessionDataHandler} from "../sessionDataHandler";
import {store} from "../store/store";
import {initConfig} from "../store/actions/initActions";
import {initLogger} from "../logger";
import {baseUrl} from "../../../constants/defaultValues";
import WebchatWidget from "./WebchatWidget";
import {useUser} from "../../../contexts";
import {useEffect} from "react";

const defaultConfig: ConfigState = {
  serverUrl: baseUrl,
  theme: {
    isLight: true,
  },
  fileAttachment: {
    enabled: true,
    maxFileSize: 16777216, // 16 MB
    acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf"],
  },
};
const WebchatWidgetWrapper = ({config}: {config?: ConfigState}) => {
  const {isAgent} = useUser();
  const mergedConfig = merge({}, defaultConfig, config);
  sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
  initLogger();
  useEffect(() => {
    store.dispatch(initConfig(mergedConfig));
  }, []);
  if (isAgent) {
    return null;
  }
  return <WebchatWidget />;
};

export default WebchatWidgetWrapper;
