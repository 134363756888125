import {axiosInstance} from "../../ServerApiManager";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  userId: string;
  accountId: string;
  interactionId: number;
};
type Data = boolean;

export const updateInteractionAccountId = async (variables: Variables): Promise<Data> => {
  await axiosInstance.put(`/backoffice/interaction/${variables.interactionId}`, {
    account_id: variables.accountId,
  });

  return true;
};

export const useUpdateInteractionAccountId = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateInteractionAccountId, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", variables.userId, "timeline"],
  });
