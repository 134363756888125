import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {FileRead, FileType} from "declerations/server/files_models";
import {appendUrlParams} from "./utils";

type Variables = {
  excludeFiles?: FileType[];
  includeFiles?: FileType[];
  fileIds: string[];
  isAgent?: boolean;
  tab?: string;
};
type Data = FileRead[];

export const getFilesByIds = async (variables: Variables): Promise<Data> => {
  let result;
  const appendedQueryParams = appendUrlParams({
    excludeFiles: variables.excludeFiles,
    includeFiles: variables.includeFiles,
    fileIds: variables.fileIds,
  });
  result = await axiosInstance.get<Data, AxiosResponse<Data>>(`/backoffice/files/${appendedQueryParams}`);
  return result.data;
};

export const useGetFilesByIdsQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["files", variables.fileIds], getFilesByIds, variables, options);
};
