import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {UserCommunicationPreferences} from "../../../declerations/server";

type Variables = {};

type Data = UserCommunicationPreferences;

export const getCommunicationPreferences = async (variables: Variables): Promise<Data> => {
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(`/frontend/user/communication_preferences`);
  return result.data;
};

export const useGetCommunicationPreferences = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getCommunicationPreferences, options);

export const useGetCommunicationPreferencesQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(
    ["user", "communication", "methods"],
    getCommunicationPreferences,
    variables,
    options
  );
};
