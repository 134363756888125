import {experimentalStyled as styled} from "@mui/material/styles";
import {StaticDatePicker} from "@mui/x-date-pickers";

export const StyledDatePicker = styled(StaticDatePicker)(({theme}) => ({
  ".MuiPickersDay-root": {
    margin: "0px",
    fontSize: "16px",
    fontWeight: 600,
  },
  ".MuiDayCalendar-weekContainer": {
    margin: "0px",
  },
  ".Mui-disabled": {
    color: "#E6E6E6 !important",
  },
  ".MuiPickersSlideTransition-root": {
    color: "#E6E6E6 !important",
    minHeight: "180px",
  },
}));
