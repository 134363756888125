import {axiosInstance} from "../../ServerApiManager";

type Variables = {
  accountId?: string;
  fileId: string;
  isAgent?: boolean;
  isMobile?: boolean;
  viewMode?: boolean;
};

const getFileName = (headers: any) => {
  const filenameField = headers["content-disposition"].match(/filename="([^"]+)"/);
  return filenameField ? filenameField[1] : null;
};

export const isNotChromeOniPhone = () => {
  const userAgent = navigator.userAgent;
  const isiPhone = /iPhone/i.test(userAgent);
  const isChrome = /CriOS/i.test(userAgent);

  return isiPhone && !isChrome;
};

export const getUserFile = async (variables: Variables): Promise<{data: any; headers: any}> => {
  const url = variables?.isAgent
    ? `/backoffice/files/download/${variables.fileId}`
    : `/frontend/files/download/${variables.accountId}/${variables.fileId}`;
  const result = await axiosInstance.get(url, {
    params: {view_mode: !!variables.viewMode},
    responseType: "blob",
  });
  const {data, headers} = result;
  return {data, headers};
};
export const downloadOrViewUserFiles = async (variables: Variables): Promise<void> => {
  const {data, headers} = await getUserFile(variables);
  const fileName = getFileName(headers);
  const isSafari = isNotChromeOniPhone();
  if (
    !isSafari &&
    variables.viewMode &&
    ["pdf", "html", "png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff"].some((fileType) =>
      fileName.endsWith(fileType)
    )
  ) {
    const blob = new Blob([data], {type: headers["content-type"]});
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  } else {
    const blob = new Blob([data], {type: headers["Content-Type"]?.toString()});
    const blobUrl = window.URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  }
};

export const downloadUserFiles = async (variables: Variables): Promise<void> => {
  const {data, headers} = await getUserFile(variables);
  const fileName = getFileName(headers);
  const blob = new Blob([data], {type: headers["Content-Type"]?.toString()});
  const blobUrl = window.URL.createObjectURL(blob);
  const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(blobUrl);
};

export const viewUserFiles = async (variables: Variables): Promise<void> => {
  const {data, headers} = await getUserFile(variables);
  const fileExtension = data["type"];
  const isSafari = isNotChromeOniPhone();
  if (
    !isSafari &&
    ["pdf", "html", "png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff"].some((fileType) =>
      fileExtension.endsWith(fileType)
    )
  ) {
    const blob = new Blob([data], {type: headers["content-type"]});
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
};
