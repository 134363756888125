import React, {useCallback} from "react";
import {IActivityCard} from "./ActivityCard";
import {WebchatData} from "./activity_models";
import {Container, Divider, Chat, Link} from "bounce-ui/backoffice";
import {StyledText} from "./components/StyledText";
import {useUpdateInteractionAccountId} from "../../../../../../apis/requests/backoffice/updateInteractionAccountId";

export const WebchatItem = ({activity, user}: IActivityCard) => {
  const {event_type, chat, principal, account_id, interaction_id} = activity as WebchatData;
  const updateInteractionAccountId = useUpdateInteractionAccountId({});

  const handleAssignAccountId = useCallback(() => {
    if (user && interaction_id) {
      updateInteractionAccountId.mutateAsync({
        interactionId: interaction_id,
        userId: user.id,
        accountId: user.account.id,
      });
    }
  }, [interaction_id, user]);

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Chat chatMessages={chat} principal={principal} />;
      {!account_id && ["WEBCHAT_CHAT", "WEBCHAT_OFFLINE"].includes(event_type) && (
        <Container fullWidth sx={{alignItems: "end"}}>
          <Link onClick={handleAssignAccountId} sx={{cursor: "pointer"}}>
            <StyledText value={"Assign account ID"} bold link />
          </Link>
        </Container>
      )}
    </Container>
  );
};
