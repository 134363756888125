import React from "react";
import {Divider, Link} from "@mui/material";
import {Container, IconGlobe, Text} from "bounce-ui/consumer";
import useIsMobile from "./helpers/useIsMobile";
import {useWeglot} from "contexts/WeglotContext";

const LanguageSwitcher = () => {
  const isMobile = useIsMobile();
  const {currLanguage, setCurrLanguage} = useWeglot();

  const onClick = () => {
    const selectedLanguage = currLanguage === "en" ? "es" : "en";
    window.Weglot.switchTo(selectedLanguage);
    setCurrLanguage(selectedLanguage);
  };

  return (
    <Link onClick={onClick} sx={{padding: isMobile ? "0px 10px" : "0px 20px", cursor: "pointer"}}>
      <Container row sx={{height: "24px", gap: "5px"}}>
        <IconGlobe />
        <Text variant={"h2"} size={14} sx={{textTransform: "uppercase", color: (theme) => theme.palette.primary.main}}>
          {currLanguage}
        </Text>
        <Divider
          sx={{
            height: "100%",
            width: "1px",
            border: "none",
            backgroundColor: (theme) => theme.palette.grey[200],
          }}
        />
        <Text variant={"h2"} size={14} sx={{color: (theme) => theme.palette.grey[300]}}>
          {currLanguage === "en" ? "ES" : "EN"}
        </Text>
      </Container>
    </Link>
  );
};

export default LanguageSwitcher;
