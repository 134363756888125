import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {useUser} from "../../../contexts";
import {FrontendUserRead} from "../../../declerations/server/user_models";
import {forEach} from "lodash";

type Variables = {isAgent?: boolean};

type Data = FrontendUserRead;

export const getUser = async (variables: Variables): Promise<Data> => {
  if (variables.isAgent) {
    // @ts-ignore
    return;
  }
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(`/frontend/user`);
  forEach(result.data.accounts_switcher, (account) => {
    account.account_id = String(account.account_id);
    if (account.active_payment_plan) {
      const plan_id = account.active_payment_plan.payment_plan_id;
      account.active_payment_plan.payment_plan_id = String(plan_id);
    }
  });

  return result.data;
};

export const useGetUserQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {loggedIn, userId, isAgent} = useUser();
  return useBaseQuery<Data, Variables>(
    ["user", userId, "accounts"],
    getUser,
    {isAgent},
    {...options, enabled: !!(loggedIn && userId && !isAgent), refetchOnWindowFocus: true}
  );
};
