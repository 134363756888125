import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {DigestModel} from "../../../declerations/server/digest_models";

type Variables = {
  accountId?: string;
};

type Data = DigestModel;
export const getDigestData = async (variables: Variables): Promise<Data> => {
  if (!variables.accountId) {
    // @ts-ignore
    return;
  }
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(
    `/backoffice/accounts/digest/${variables.accountId}`
  );
  return result.data;
};

export const useGetDigestData = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["account", variables.accountId, "digest"], getDigestData, variables, options);
};
