import React, {FunctionComponent, useEffect} from "react";
import {Dialog} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {FormattedMessage} from "react-intl";
import {useLocation} from "react-router-dom";
import {useUpdateEffect} from "react-use";
import {
  CloseDialogButton,
  Container,
  ImgTermsAndConditions,
  Text,
  Button,
  Colors,
} from "../../../../bounce-ui/consumer";
import useIsMobile from "../../../../helpers/useIsMobile";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";

export type TermsAndConditionsDialogProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  termsAndConditionsTextId?: string;
};

const TermsAndConditionsDialog: FunctionComponent<TermsAndConditionsDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm, onCancel, termsAndConditionsTextId}) => {
    const {pageViewEvent} = useAmplitude();
    const modal = useModal();
    const {pathname} = useLocation();
    const isMobile = useIsMobile();

    const confirmHandler = (): void => {
      onConfirm?.();

      modal.resolve(true);
      modal.hide();
    };

    const cancelHandler = (): void => {
      onCancel?.();

      modal.resolve(false);
      modal.hide();
    };

    useUpdateEffect(() => {
      cancelHandler();
    }, [pathname]);

    useEffect(() => {
      pageViewEvent({page: Pages.T_C});
    }, []);

    return (
      <Dialog
        open={modal.visible}
        onClose={cancelHandler}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CloseDialogButton onClose={cancelHandler} />
        <Container
          sx={{
            width: isMobile ? "300px" : "525px",
            height: isMobile ? "630px" : "580px",
            gap: "20px",
            overflow: "auto",
          }}
        >
          <Container>
            <ImgTermsAndConditions />
          </Container>
          <Text translateId={"termsAndConditions"} size={22} fontWeight={"bold"} />
          <Text
            size={12}
            sx={{
              color: Colors.grey._800,
              whiteSpace: "break-spaces",
            }}
          >
            {termsAndConditionsTextId}
          </Text>
          <Button onClick={confirmHandler}>
            <FormattedMessage id={"close"} />
          </Button>
        </Container>
      </Dialog>
    );
  }
);

export default TermsAndConditionsDialog;
