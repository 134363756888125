import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseGeneratorVariable, ImportResponse} from "./utils";

export const generateUserReport = async (variables: BaseGeneratorVariable): Promise<ImportResponse> => {
  const result = await axiosInstance.post<string, AxiosResponse<ImportResponse>>(
    `/backoffice/admin/users/report/${variables.batch}`
  );

  return result.data;
};
