import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {EventData} from "../../../declerations/server";

type Variables = {
  eventData: EventData;
  isAgent?: boolean;
};

type Data = boolean;

export const addEvent = async (variables: Variables): Promise<Data> => {
  if (variables.isAgent) {
    await axiosInstance.post<string, AxiosResponse<string>>(`/backoffice/add_event`, variables.eventData);
  } else {
    await axiosInstance.post<string, AxiosResponse<string>>(`/frontend/events/add_event`, variables.eventData);
  }
  return true;
};

export const useAddEvent = (options?: BaseMutationOptions<Data, Variables>): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(addEvent, options);
