import {
  AccountUpdateData,
  ActivityData,
  ChannelType,
  CollectionNoticeTriggerData,
  ContactUsData,
  DisputeData,
  EmailData,
  LetterData,
  PaymentData,
  PaymentPlanData,
  PhoneCallData,
  SMSData,
  UserActivityData,
  WebchatData,
} from "./activity_models";
import {formatCurrency} from "../../../../../../helpers/formatNumber";
import {EnumInfo} from "../../../../../../declerations/internal";
import {AllPlanFrequencies} from "../../../../../../declerations/enums";
import InputUtils from "../../../../../../helpers/InputUtils";
import {isEmpty, map} from "lodash";
import {getPaymentPlanSubtitleByType} from "./utils";

interface ActivityDetails {
  subtitle: string;
  disable: boolean;
}

export enum AccountUpdateAction {
  ADD = "add",
  DELETE = "delete",
  UPDATE = "update",
}

const actionLabelMap: {[key in AccountUpdateAction]: string} = {
  [AccountUpdateAction.ADD]: "A value was added to",
  [AccountUpdateAction.DELETE]: "A value was deleted from",
  [AccountUpdateAction.UPDATE]: "Update in",
} as const;

export const planTypeToLabelMap: Map<AllPlanFrequencies, EnumInfo> = new Map<AllPlanFrequencies, EnumInfo>([
  ["weekly", {labelKey: "Fixed installments plan"}],
  ["bi_weekly", {labelKey: "Fixed installments plan"}],
  ["monthly", {labelKey: "Fixed installments plan"}],
  ["one_time", {labelKey: "One-time payment"}],
  ["first_and_fifteenth", {labelKey: "Fixed installments plan"}],
  ["non_linear", {labelKey: "Custom plan"}],
]);

export const ChannelToSubtitleMap: Map<ChannelType, (activity: ActivityData) => ActivityDetails> = new Map<
  ChannelType,
  (activity: ActivityData) => ActivityDetails
>([
  ["phone_call", (activity: ActivityData) => getPhoneCallSubtitle(activity)],
  ["sms", (activity: ActivityData) => getSMSSubtitle(activity)],
  ["email", (activity: ActivityData) => getEmailSubtitle(activity)],
  ["letter", (activity: ActivityData) => getLetterSubtitle(activity)],
  ["webchat", (activity: ActivityData) => getWebchatSubtitle(activity)],
  ["webchat_offline", (activity: ActivityData) => getWebchatSubtitle(activity)],
  ["payment_plan", (activity: ActivityData) => getPaymentPlanSubtitle(activity)],
  ["payment", (activity: ActivityData) => getPaymentSubtitle(activity)],
  ["account_update", (activity: ActivityData) => getAccountUpdateSubtitle(activity)],
  ["contact_us", (activity: ActivityData) => getContactUsSubtitle(activity)],
  ["dispute", (activity: ActivityData) => getDisputeSubtitle(activity)],
  ["user_activity", (activity: ActivityData) => getUserActivitySubtitle(activity)],
  ["trigger", (activity: ActivityData) => getCollectionNoticeTriggerSubtitle(activity)],
]);

const getPhoneCallSubtitle = (activity: ActivityData): ActivityDetails => {
  const {event_type, call_outcome, call_reason} = activity as PhoneCallData;
  let subtitle;
  if (event_type === "PHONE_CALL_MISSED_INCOMING") {
    subtitle = "No answer";
  } else if (!isEmpty(call_outcome)) {
    const prettifiedOutcomes = map(call_outcome, (outcome) => InputUtils.prettierSnakeCase(outcome));
    subtitle = prettifiedOutcomes.join(", ");
  } else {
    subtitle = "Not Available";
  }
  const disable = event_type !== "SCHEDULED_PHONE_CALL" && !call_outcome && !call_reason;
  return {subtitle: `Call outcome: ${subtitle}`, disable};
};
const getSMSSubtitle = (activity: ActivityData): ActivityDetails => {
  const {message, to_phone_number} = activity as SMSData;
  const subtitle = `Message: ${isEmpty(message) ? "no content" : message}`;
  return {subtitle, disable: !to_phone_number};
};

const getEmailSubtitle = (activity: ActivityData): ActivityDetails => {
  const {subject, to_email, email_file_id, attachments, body} = activity as EmailData;
  const disable = !to_email && !email_file_id && isEmpty(attachments) && !body;
  const subtitle = `Message: ${isEmpty(subject) ? "no content" : subject}`;
  return {subtitle, disable};
};

const getLetterSubtitle = (activity: ActivityData): ActivityDetails => {
  const {letter_file_id, recipient} = activity as LetterData;
  const subtitle = "Type: Validation Notice";
  const disable = !letter_file_id && !recipient;
  return {subtitle, disable};
};

const getWebchatSubtitle = (activity: ActivityData): ActivityDetails => {
  const numberOfMessages = (activity as WebchatData).chat.length;
  const subtitle = numberOfMessages ? `${numberOfMessages} messages` : "Chat history does not exist";
  return {subtitle, disable: !numberOfMessages};
};

const getPaymentPlanSubtitle = (activity: ActivityData): ActivityDetails => {
  const {is_interim_plan, installment_frequency, payment_plan_id, is_hardship_plan} = activity as PaymentPlanData;
  const subtitle = getPaymentPlanSubtitleByType({
    isInterimPlan: is_interim_plan || false,
    isHardship: is_hardship_plan || false,
    frequency: installment_frequency,
  });
  return {subtitle: `Type: ${subtitle}`, disable: !payment_plan_id};
};

const getPaymentSubtitle = (activity: ActivityData): ActivityDetails => {
  const {paid_to, payment_plan_id, amount, original_due_date, new_due_date, event_type} = activity as PaymentData;
  const subtitle = `Amount: ${amount ? formatCurrency(amount) : "Unknown"}`;
  const updateInstallmentDueDateDisableCondition = !original_due_date && !new_due_date;
  const otherPaymentsDisableCondition = !paid_to && !payment_plan_id;
  const disable =
    event_type === "INSTALLMENT_DUE_DATE_UPDATED"
      ? updateInstallmentDueDateDisableCondition
      : otherPaymentsDisableCondition;
  return {subtitle, disable};
};

const getAccountUpdateSubtitle = (activity: ActivityData): ActivityDetails => {
  const {action, section, diff} = activity as AccountUpdateData;
  const subtitle = `${actionLabelMap[action]} ${section}`;
  return {subtitle, disable: isEmpty(diff)};
};

const getContactUsSubtitle = (activity: ActivityData): ActivityDetails => {
  const {text, email} = activity as ContactUsData;
  const subtitle = `Message: ${isEmpty(text) ? "no content" : text}`;
  return {subtitle, disable: !email};
};

const getDisputeSubtitle = (activity: ActivityData): ActivityDetails => {
  const {dispute_type, dispute_additional_text, files_names} = activity as DisputeData;
  const subtitle = `Type: ${InputUtils.prettierSnakeCase(dispute_type) ?? "Unknown"}`;
  const disable = !dispute_additional_text && isEmpty(files_names);
  return {subtitle, disable};
};

const getCollectionNoticeTriggerSubtitle = (activity: ActivityData): ActivityDetails => {
  const {trigger_type} = activity as CollectionNoticeTriggerData;
  const subtitle = `Type: ${InputUtils.prettierSnakeCase(trigger_type)}`;
  return {subtitle, disable: false};
};

const getUserActivitySubtitle = (activity: ActivityData): ActivityDetails => {
  const {event_type, payment_plan_offers} = activity as UserActivityData;
  const subtitle = InputUtils.prettierSnakeCase(event_type);
  return {subtitle: `Event: ${subtitle}`, disable: isEmpty(payment_plan_offers)};
};
