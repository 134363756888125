import {FileFormatTypes, ReportType} from "../../../../declerations/enums";
import {gzip} from "pako";

export type BaseImportVariable = {
  file: File;
  importType: string;
  fileFormat?: FileFormatTypes;
  settings?: {
    report_date?: string;
    report_description?: string;
    report_type?: ReportType;
    file_format?: FileFormatTypes;
    override_details?: boolean;
  };
};

export type BaseGeneratorVariable = {
  reportType?: ReportType;
  batch: string;
};

export type ImportResponse = {
  file_id?: number;
  import_type?: ReportType;
};
export function setDownloadFileAttributes(fileData: Blob, fileName: string) {
  const href = URL.createObjectURL(fileData);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export const compressFile = async (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      try {
        const compressed = gzip(new Uint8Array(reader.result as ArrayBuffer));
        const blobFile = new Blob([compressed], {type: "application/gzip"});
        resolve(blobFile);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(reader.error);
  });
};
