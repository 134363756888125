import React, {ReactNode} from "react";
import {TextField as MuiTextField, TextFieldProps, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
import {useIntl} from "react-intl";
import {isString} from "lodash";

export type TextFieldType = TextFieldProps & {
  children?: ReactNode;
  InputSx?: SxProps<Theme>;
};

export const TextField = ({label, InputProps, children, InputSx, sx, ...props}: TextFieldType) => {
  const intl = useIntl();
  return (
    <MuiTextField
      label={isString(label) ? intl.formatMessage({id: label}) : label}
      InputProps={{
        ...InputProps,
        sx: {
          alignItems: "start",
          borderRadius: "8px",
          ...InputSx,
        },
      }}
      sx={{zIndex: 0, ...sx}}
      {...props}
    >
      {children}
    </MuiTextField>
  );
};
