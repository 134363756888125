import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {Content} from "../../../declerations/server";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  user_id: string;
  account_id: string;
  contact?: string;
  date?: string;
  content: Content;
};

type Data = boolean;

export const sendArbitraryContent = async (variables: Variables): Promise<Data> => {
  await axiosInstance.post<string, AxiosResponse<string>>(`/backoffice/send_arbitrary_content/${variables.user_id}`, {
    account_id: variables.account_id,
    content: variables.content,
    contact: variables.contact,
    date: variables.date,
    run_police: true,
    source: "backoffice",
  });
  return true;
};

export const useSendArbitraryContent = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(sendArbitraryContent, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", variables.user_id, "timeline"],
  });
