import React, {FunctionComponent, useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {LocalizationProvider, TimeClockProps} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useLocation} from "react-router-dom";
import {useUpdateEffect} from "react-use";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {styled} from "@mui/material/styles";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useAmplitude} from "contexts";
import {Pages} from "bi-events/enum";
import {StyledDatePicker} from "./DatePicker/style";
import {Button, CloseDialogButton, IconBulb, Text, Container, Colors} from "./index";
import {DATE_FORMAT_V2} from "../../pages/backoffice/information-panel/components/dialogs/common";
dayjs.extend(utc);
dayjs.extend(timezone);

const getFutureDate = (date: Dayjs, daysToAdd: number): Dayjs => {
  return date.add(daysToAdd, "day");
};

interface IChooseDateProps {
  onConfirm: (event: any) => void;
  onCancel?: () => void;
  shouldDisableDate?: (date: unknown) => boolean;
  startDate: Dayjs;
  isPastAllowed?: boolean;
  isChooseYearAllowed?: boolean;
  displayTip?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  title?: string;
  description?: string;
}
const StyledArrowLeft = styled(KeyboardArrowLeft)<{disabled: boolean}>(({theme}) => ({
  color: Colors.grey._900,
}));

const StyledArrowRight = styled(KeyboardArrowRight)<{disabled: boolean}>(({theme}) => ({
  color: Colors.grey._900,
}));

export const slots: TimeClockProps<any>["slots"] = {
  leftArrowIcon: StyledArrowLeft,
  rightArrowIcon: StyledArrowRight,
};

const ChooseDateDialog: FunctionComponent<IChooseDateProps & NiceModalHocProps> = NiceModal.create(
  ({
    onConfirm,
    onCancel,
    shouldDisableDate,
    startDate,
    isPastAllowed = false,
    isChooseYearAllowed = false,
    minDate,
    maxDate,
    displayTip = false,
    title = "startDate",
    description = "whenDoYouWantToStart",
  }) => {
    const {pageClickEvent, pageViewEvent} = useAmplitude();
    const [selectedDate, setSelectedDate] = useState<Dayjs>(startDate ?? dayjs());
    const modal = useModal();
    const {pathname} = useLocation();
    const shouldDisplayTip = !isPastAllowed && displayTip;
    const confirmHandler = (): void => {
      pageClickEvent({page: Pages.CHOOSE_DATE, cta: "set_date", data: selectedDate});
      onConfirm(selectedDate);
      modal.resolve(true);
      modal.hide();
    };

    const cancelHandler = (): void => {
      onCancel?.();

      modal.resolve(false);
      modal.hide();
    };

    useUpdateEffect(() => {
      cancelHandler();
    }, [pathname]);

    const getMinDate = (): Dayjs | null => {
      if (minDate) {
        return minDate;
      }
      return isPastAllowed ? null : startDate.tz("America/New_York");
    };
    const getMaxDate = (): Dayjs | null => {
      if (maxDate) {
        return maxDate;
      }
      return dayjs(getFutureDate(getMinDate() || dayjs(), 30));
    };

    useEffect(() => {
      pageViewEvent({page: Pages.CHOOSE_DATE});
    }, []);

    return (
      <Dialog
        open={modal.visible}
        onClose={cancelHandler}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CloseDialogButton onClose={modal.hide} />
        <Container fullWidth sx={{gap: "5px", height: "650px", justifyContent: "space-around", overflow: "auto"}}>
          <Container fullWidth sx={{alignItems: "start"}}>
            <Text translateId={title} fontWeight={"bold"} size={22} />
            <Text translateId={description} />
          </Container>
          <Text size={26} fontWeight={"bold"}>
            {dayjs(selectedDate).format(DATE_FORMAT_V2)}
          </Text>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
              defaultValue={dayjs()}
              value={selectedDate || null}
              minDate={getMinDate()}
              maxDate={getMaxDate()}
              onChange={(event: any) => {
                setSelectedDate(event);
              }}
              shouldDisableDate={shouldDisableDate}
              views={isChooseYearAllowed ? ["day", "year"] : ["day"]}
              slots={slots}
              slotProps={{
                actionBar: {
                  actions: [],
                },
                toolbar: {hidden: true},
              }}
            />
          </LocalizationProvider>
          <Container>
            <Button onClick={confirmHandler}>
              <FormattedMessage id={"setDate"} />
            </Button>
            {shouldDisplayTip && (
              <Container row sx={{gap: "2px"}}>
                <IconBulb />
                <Text translateId={"tip"} fontWeight={"medium"} size={14} sx={{display: "inline"}}>
                  <Text translateId={"mostUsersPick"} fontWeight={"light"} size={14} sx={{display: "inline"}} />
                </Text>
              </Container>
            )}
          </Container>
        </Container>
      </Dialog>
    );
  }
);

export {ChooseDateDialog, getFutureDate};
