import {PaymentPlanOffer, PaymentPlanOffers} from "../../../../declerations/server/payment_plan_models";
import {experimentalStyled as styled} from "@mui/material/styles";
import {IconButton, Link, ToggleButton} from "@mui/material";
import React, {ReactNode} from "react";
import {Card, Colors, Container, Text} from "bounce-ui/consumer";
import useIsMobile from "../../../../helpers/useIsMobile";
import {prettifyCurrencyRounded} from "../../../../helpers/currency";

interface ISummaryText {
  field: string;
  value: string;
  icon?: ReactNode;
  lineCross?: boolean;
  onClickIcon?: () => void;
}

interface ITextLabel {
  title: string;
  subtitle: string;
  icon: ReactNode;
}

interface IPlanSuggestion {
  index: number;
  firstAmount: number;
  duration: number;
  secondAmount: number;
  selected: boolean;
  handleSelect: (index: number) => void;
}

const SummarySectionTitle = ({title}: {title: string}) => {
  return (
    <Text
      translateId={title}
      size={14}
      variant={"body2"}
      sx={{textTransform: "uppercase", color: Colors.grey._500, letterSpacing: "0.5px"}}
    />
  );
};

const SummaryText = ({field, value, icon, lineCross = false, onClickIcon}: ISummaryText) => {
  return (
    <Container row fullWidth sx={{justifyContent: "space-between"}}>
      <Text translateId={field} sx={{textAlign: "start"}}>
        :
      </Text>
      <Container row sx={{textAlign: "end"}}>
        <Text fontWeight={"bold"} sx={{...(lineCross && {textDecoration: "line-through"})}}>
          {value}
        </Text>
        {icon && (
          <IconButton onClick={onClickIcon} sx={{padding: "0px"}}>
            {icon}
          </IconButton>
        )}
      </Container>
    </Container>
  );
};

const CustomToggleButton = styled(ToggleButton)(({theme}) => ({
  fontSize: "13px",
  fontWeight: "medium",
  margin: "5px",
  height: "35px",
  color: Colors.primary._800,
  border: "none",
  whiteSpace: "nowrap",
  borderRadius: "0px",
  "&.Mui-selected": {
    color: Colors.bw.white,
    backgroundColor: Colors.primary._800,
  },
  "&:not(.Mui-selected)": {
    backgroundColor: Colors.grey._100,
  },
}));

const getFirstFrequencyWithOptions = (planOffers: PaymentPlanOffers) => {
  if (planOffers) {
    if (planOffers.weekly.length > 0) return "weekly";
    if (planOffers.bi_weekly.length > 0) return "bi_weekly";
    if (planOffers.first_and_fifteenth.length > 0) return "first_and_fifteenth";
    if (planOffers.monthly.length > 0) return "monthly";
  }
  return null;
};

const TextLabel = ({title, subtitle, icon}: ITextLabel) => {
  const isMobile = useIsMobile();

  return (
    <Container row sx={{textAlign: "start", alignItems: "start", width: isMobile ? "100%" : "320px"}}>
      <div>{icon}</div>
      <Text translateId={title} variant={"body2"} sx={{display: "inline"}}>
        <Text translateId={subtitle} variant={"body1"} sx={{display: "inline"}} />
      </Text>
    </Container>
  );
};

const PlanSuggestion = ({index, firstAmount, duration, secondAmount, selected, handleSelect}: IPlanSuggestion) => {
  const isMobile = useIsMobile();

  const onClick = () => {
    handleSelect(index);
  };
  return (
    <Link onClick={onClick} sx={{cursor: "pointer"}}>
      <Card
        row
        sx={{
          width: isMobile ? "100%" : "550px",
          border: "2px solid",
          justifyContent: "center",
          borderColor: (theme) => (selected ? Colors.primary._800 : Colors.bw.white),
        }}
      >
        <Text variant={"body2"} sx={{display: "inline"}}>
          {`${prettifyCurrencyRounded(firstAmount)}/mo for ${duration} months, `}
          <Text variant={"body1"} sx={{display: "inline"}}>
            {`then ${prettifyCurrencyRounded(secondAmount)}/mo`}
          </Text>
        </Text>
      </Card>
    </Link>
  );
};

const BulletText = ({text}: {text: string}) => {
  return (
    <Container fullWidth row sx={{alignItems: "start", padding: "0px 10px"}}>
      &bull;
      <Text translateId={text} size={14} variant={"body1"} sx={{color: Colors.secondary._900}} />
    </Container>
  );
};

const sortPlansByInstallment = (paymentOffers: PaymentPlanOffer[]): PaymentPlanOffer[] => {
  return paymentOffers.sort((a, b) => {
    if (b.hardship_payment_plan && a.hardship_payment_plan) {
      return a.hardship_payment_plan?.installment_size - b.hardship_payment_plan?.installment_size;
    }
    return 0;
  });
};

export {
  CustomToggleButton,
  getFirstFrequencyWithOptions,
  SummarySectionTitle,
  SummaryText,
  TextLabel,
  PlanSuggestion,
  BulletText,
  sortPlansByInstallment,
};
