import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IActivityCard} from "./ActivityCard";
import {EmailData} from "./activity_models";
import {Container, Divider, Link} from "bounce-ui/backoffice";
import DisplayDownloadedContent from "./components/DisplayDownloadedContent";
import {getUserFiles} from "../../../../../../apis/requests/files";
import {isEmpty, map} from "lodash";
import DocumentDetails from "pages/account/account-documentations/DocumentDetails";
import {FileRead} from "../../../../../../declerations/server/files_models";
import LoadingCenter from "../../../../../../components/common/LoadingCenter";
import {useDeleteFutureCommunication} from "../../../../../../apis/requests/backoffice/deleteFutureCommunication";
import {StyledText} from "./components/StyledText";
import {useUpdateInteractionAccountId} from "../../../../../../apis/requests/backoffice/updateInteractionAccountId";
import {CommunicationDirection} from "./utils";
import NiceModal from "@ebay/nice-modal-react";
import SendEmailDialog from "../../../components/dialogs/SendEmailDialog";
import {useGetEmailsForSendArbitraryEmailDialog} from "../../../../../../hooks/useGetEmailsForSendArbitraryEmailDialog";

export const EmailItem = ({activity, user}: IActivityCard) => {
  const [attachedFiles, setAttachedFiles] = useState<FileRead[]>([]);
  const {
    event_type,
    interaction_id,
    id,
    to_email,
    from_email,
    email_file_id,
    attachments,
    body,
    account_id,
    direction,
    subject,
  } = activity as EmailData;
  const userId = useMemo<string>(() => (user && user.id) || "", [user]);
  const isAttachments = !isEmpty(attachments);
  const accountId = useMemo<string>(() => {
    return user?.account.id || "";
  }, [user]);
  const deleteFutureCommunication = useDeleteFutureCommunication({});
  const updateInteractionAccountId = useUpdateInteractionAccountId({});
  const {emailsToDialog, defaultEmail} = useGetEmailsForSendArbitraryEmailDialog({userId});

  const handleAssignAccountId = useCallback(() => {
    if (user && interaction_id) {
      updateInteractionAccountId.mutateAsync({
        interactionId: interaction_id,
        userId: user.id,
        accountId,
      });
    }
  }, [interaction_id, user]);

  const handleCancelEvent = useCallback(() => {
    if (id) {
      deleteFutureCommunication.mutateAsync({timedInteractionId: id, userId});
    }
  }, [id, userId]);

  const fetchData = async () => {
    const result = await getUserFiles({
      userId,
      accountId,
      fileIds: attachments,
      isAgent: true,
      tab: "timeline" + email_file_id,
    });
    if (result) setAttachedFiles(result);
  };

  useEffect(() => {
    if (isAttachments) {
      fetchData();
    }
  }, []);

  const handleForwardClick = () => {
    NiceModal.show(SendEmailDialog, {
      userEmails: emailsToDialog,
      userId,
      accountId: account_id,
      defaultEmail,
      subject: "FW: " + subject,
      shouldIncludeBody: false,
      emailFileId: email_file_id,
      shouldDisplayAttachmentWarning: !isEmpty(attachments),
    });
  };

  if (isAttachments && isEmpty(attachedFiles)) {
    return <LoadingCenter />;
  }

  return (
    <Container fullWidth>
      <Divider orientation={"horizontal"} sx={{width: "100%"}} />
      <Container fullWidth sx={{alignItems: "start"}}>
        {id && <StyledText value={`ID: ${id}`} />}
        {to_email && <StyledText value={`To: ${to_email}`} />}
        {from_email && <StyledText value={`From: ${from_email}`} />}
        {body && <StyledText value={`Content: ${body}`} />}
        {email_file_id && (
          <Container row sx={{alignItems: "start"}}>
            <DisplayDownloadedContent interaction_type="Email" file_id={email_file_id} />
            {direction === CommunicationDirection.OUTBOUND && (
              <Container row>
                <StyledText value={" | "} bold link />
                <Link onClick={handleForwardClick} sx={{cursor: "pointer"}}>
                  <StyledText value={"Forward"} bold link />
                </Link>
              </Container>
            )}
          </Container>
        )}
        {!isEmpty(attachedFiles) && (
          <>
            <StyledText value={`Attachments:`} />
            {map(attachedFiles, (file, index) => {
              return <DocumentDetails key={`file-${index}`} file={file} accountId={accountId} isAttachment />;
            })}
          </>
        )}
        {event_type === "SCHEDULED_EMAIL" && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleCancelEvent} sx={{cursor: "pointer"}}>
              <StyledText value={"Cancel event"} bold link />
            </Link>
          </Container>
        )}
        {!account_id && ["EMAIL_RECEIVED", "EMAIL_DELIVERED", "EMAIL_SENT"].includes(event_type) && (
          <Container fullWidth sx={{alignItems: "end"}}>
            <Link onClick={handleAssignAccountId} sx={{cursor: "pointer"}}>
              <StyledText value={"Assign account ID"} bold link />
            </Link>
          </Container>
        )}
      </Container>
    </Container>
  );
};
