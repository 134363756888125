import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";
import {ExampleFileTypes} from "../../../../declerations/enums";
import {setDownloadFileAttributes} from "./utils";

type Variables = {
  exampleFileType: ExampleFileTypes;
};

export const downloadExampleFile = async (variables: Variables): Promise<void> => {
  const result = await axiosInstance.get<Blob, AxiosResponse<Blob>>(
    `/backoffice/admin/examples/${variables.exampleFileType}`,
    {
      responseType: "blob",
    }
  );
  setDownloadFileAttributes(result.data, `${variables.exampleFileType.toLowerCase()}-example.csv`);
};
