import {IEmailForm, ILetterForm, IMessageForm, IPhoneCallForm} from "./useCommunicationForms";
import dayjs from "dayjs";
import {LetterTemplateKey} from "../../../../../declerations/server";

const clearPhoneCallForm = (setMessage: (form: IPhoneCallForm) => void, defaultPhone: string) => {
  setMessage({
    phone: defaultPhone,
    date: dayjs(),
    body: "",
  });
};

const getPhoneCallDefaultValues = (phoneCallForms: IPhoneCallForm, defaultPhone: string) => {
  return {
    phone: phoneCallForms.phone || defaultPhone,
    date: phoneCallForms.date || dayjs(),
    body: phoneCallForms.body || "",
  };
};

const clearLetterForm = (
  setLetter: (form: ILetterForm) => void,
  defaultAddress: string,
  templateKey: LetterTemplateKey
) => {
  setLetter({
    address: defaultAddress,
    description: "",
    files: [],
    templateKey,
  });
};

const getLetterDefaultValues = (
  letterForms: ILetterForm,
  defaultAddress: string,
  defaultTemplateKeyType: LetterTemplateKey
) => {
  return {
    address: letterForms.address || defaultAddress,
    description: letterForms.description,
    files: letterForms.files,
    templateKey: letterForms.templateKey || defaultTemplateKeyType,
  };
};

const clearEmailForm = (setEmail: (form: IEmailForm) => void, defaultEmail: string, subject: string) => {
  setEmail({
    email: defaultEmail,
    date: dayjs(),
    message: "",
    subject,
    files: [],
  });
};

const getEmailDefaultValues = (emailForms: IEmailForm, defaultEmail: string, subject: string) => {
  return {
    email: emailForms?.email || defaultEmail,
    date: emailForms?.date || dayjs(),
    message: emailForms?.message || "",
    subject: emailForms?.subject || subject,
    files: emailForms?.files,
  };
};

const clearMessageForm = (setMessage: (form: IMessageForm) => void, defaultPhone: string) => {
  setMessage({
    phone: defaultPhone,
    date: dayjs(),
    message: "",
  });
};

const getMessageDefaultValues = (messageForms: IMessageForm, defaultPhone: string) => {
  return {
    phone: messageForms.phone || defaultPhone,
    date: messageForms.date || dayjs(),
    message: messageForms.message || "",
  };
};

export {
  clearPhoneCallForm,
  getPhoneCallDefaultValues,
  clearLetterForm,
  getLetterDefaultValues,
  clearEmailForm,
  getEmailDefaultValues,
  clearMessageForm,
  getMessageDefaultValues,
};
