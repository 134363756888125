import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseGeneratorVariable, ImportResponse} from "./utils";

export const generateWashReport = async (variables: BaseGeneratorVariable): Promise<ImportResponse> => {
  const result = await axiosInstance.post<string, AxiosResponse<ImportResponse>>(
    `/backoffice/admin/wash/generate/${variables.reportType}?batch=${variables.batch}`
  );

  return result.data;
};
