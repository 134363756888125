import {axiosInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";

type Variables = {
  lenderFileId: string;
};

export const downloadLenderFile = async (variables: Variables): Promise<void> => {
  const result = await axiosInstance.get<Blob, AxiosResponse<Blob>>(
    `/backoffice/admin/lender-files/download/${variables.lenderFileId}`,
    {
      responseType: "blob",
    }
  );

  const href = URL.createObjectURL(result.data);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${variables.lenderFileId.toLowerCase()}.csv`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
