import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  userId: string;
  accountId: string;
  fileId: string;
};

type Data = boolean;

export const deleteFileFromUser = async (variables: Variables): Promise<boolean> => {
  const result = await axiosInstance.delete<boolean, AxiosResponse<boolean>>(
    `/backoffice/files/${variables.userId}/${variables.accountId}/${variables.fileId}`
  );
  return result.data;
};

export const useDeleteFileFromUser = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(deleteFileFromUser, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", "files"],
  });
