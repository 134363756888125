import {experimentalStyled as styled} from "@mui/material/styles";
import {Stepper as MuiStepper} from "@mui/material";
import {Colors} from "../../colors";

export const StepperStyled = styled(MuiStepper)(({theme}) => ({
  ".MuiStepConnector-line": {
    borderColor: Colors.grey._200,
    borderWidth: "3px",
    width: "100%",
  },
  ".MuiStep-root": {
    margin: "0px",
    padding: "0px",
  },
  ".MuiStepLabel-iconContainer": {
    margin: "0px",
    padding: "0px",
  },
  ".MuiStepIcon-text": {
    fontWeight: "700",
  },
  ".MuiSvgIcon-root": {
    width: "1.25rem",
    height: "1.25rem",
  },
  circle: {
    r: 10,
    color: Colors.primary._800,
  },
}));
