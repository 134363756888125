import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {LenderBase} from "declerations/server/lender_models";

type Variables = {lender_name: string};

type Data = LenderBase;

export const getLenderDetails = async (variables: Variables): Promise<Data> => {
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(
    `/frontend/lenders/${variables.lender_name}/lender_details`
  );
  return result.data;
};

export const useGetLenderDetailsQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(["lender", variables.lender_name], getLenderDetails, variables, options);
};
