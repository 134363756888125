// ----------------------------------------------------------------------
import "./fonts/fonts.css";

const PRIMARY_FONT = "HelveticaNowText, Montserrat, sans-serif";
const PRIMARY_FONT_BOLD = "HelveticaNowTextMedium, Montserrat, sans-serif";
const SECONDARY_FONT = "HelveticaNowDisplay, Montserrat, sans-serif";
const SECONDARY_FONT_BOLD = "HelveticaNowDisplayMedium, Montserrat, sans-serif";

const typography = {
  fontFamily: PRIMARY_FONT,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: SECONDARY_FONT,
  },
  h2: {
    fontFamily: SECONDARY_FONT_BOLD,
  },
  body1: {
    fontFamily: PRIMARY_FONT,
  },
  body2: {
    fontFamily: PRIMARY_FONT_BOLD,
  },
} as const;

export default typography;
