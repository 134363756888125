import {Icon} from "@iconify/react";
import closeCircleFill from "@iconify/icons-eva/close-circle-fill";
import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";
import {Colors} from "../../bounce-ui/colors";

// ----------------------------------------------------------------------

export default function Chip(theme: Theme): Components {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={closeCircleFill} />,
      },
      styleOverrides: {
        label: {
          paddingLeft: theme.spacing(0.8),
          paddingRight: theme.spacing(0.8),
        },
        outlined: {
          borderColor: Colors.grey._400,
          "&.MuiChip-colorPrimary": {
            borderColor: Colors.primary._800,
          },
        },
        colorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.main,
        },
        colorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.main,
        },
        colorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.main,
        },
        colorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.main,
        },
      },
    },
  };
}
