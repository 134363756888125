import React from "react";
import {Card, Container, Text, TextField} from "../../../../bounce-ui/consumer";
import useIsMobile from "../../../../helpers/useIsMobile";
import {InputAdornment, Typography} from "@mui/material";
import {formatCurrency} from "../../../../helpers/formatNumber";
import {Colors} from "../../../../bounce-ui/colors";

interface ICustomPaymentBox {
  isValid: boolean;
  amount: number | undefined;
  totalBalance: number;
  setAmount: (amount: number) => void;
  title?: string;
  label?: string;
  isDialog?: boolean;
}
const CustomPaymentBox = ({
  isValid,
  totalBalance,
  amount,
  setAmount,
  isDialog = false,
  title,
  label,
}: ICustomPaymentBox) => {
  const isMobile = useIsMobile();
  return (
    <Container fullWidth>
      <Card
        fullWidth
        isDialog={isDialog}
        sx={{
          gap: "16px",
          padding: isMobile ? "20px" : "30px 75px",
        }}
      >
        {title && <Text translateId={title} fontWeight={"medium"} sx={{alignItems: "start", width: "100%"}} />}
        <TextField
          type={"number"}
          fullWidth
          onChange={(event) => setAmount(Number(event.target.value) * 100)}
          error={!isValid}
          helperText={
            !isValid &&
            amount &&
            amount !== 0 && (
              <Text
                size={12}
                variant={"h1"}
                translateId={amount > totalBalance ? "higherThanTotalBalance" : "invalidAmount"}
                sx={{color: Colors.red._900}}
              />
            )
          }
          label={label}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={{color: "text.primary", fontWeight: "bold"}}>{"$"}</Typography>
              </InputAdornment>
            ),
            inputProps: {
              style: {
                fontWeight: "bold",
              },
            },
          }}
        />
      </Card>
      <Text
        translateId={isDialog ? "totalRemainingBalance" : "totalBalanceValue"}
        size={14}
        values={{totalBalance: formatCurrency(totalBalance)}}
        sx={{color: Colors.grey._700}}
      />
    </Container>
  );
};

export default CustomPaymentBox;
