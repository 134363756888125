import {ReactNode} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUser} from "../../contexts";
import {urls} from "routes/urls";

type AuthGuardProps = {
  children: ReactNode;
};

const AuthGuard = ({children}: AuthGuardProps) => {
  const {loggedIn} = useUser();
  const {pathname, search} = useLocation();
  const queryParams = new URLSearchParams(search);
  const userId = queryParams.get("id");

  if (search && !loggedIn && userId) {
    return <Navigate to={urls.login.url + search} state={{origin: pathname + search}} />;
  } else if (!loggedIn) {
    return <Navigate to={urls.login.url} state={{origin: pathname}} />;
  }
  return <>{children}</>;
};

export default AuthGuard;
