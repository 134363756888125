import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {FileRead, FileType} from "declerations/server/files_models";
import {appendUrlParams} from "./utils";

type Variables = {
  userId: string;
  accountId?: string;
  excludeFiles?: FileType[];
  includeFiles?: FileType[];
  fileIds?: string[];
  isAgent?: boolean;
  tab?: string;
};
type Data = FileRead[];

export const getUserFiles = async (variables: Variables): Promise<Data> => {
  if (!variables.accountId) {
    // @ts-ignore
    return;
  }
  let result;
  const appendedQueryParams = appendUrlParams({
    excludeFiles: variables.excludeFiles,
    includeFiles: variables.includeFiles,
    fileIds: variables.fileIds,
  });

  if (variables.isAgent) {
    result = await axiosInstance.get<Data, AxiosResponse<Data>>(`/backoffice/files/${appendedQueryParams}`, {
      params: {user_id: variables.userId, account_id: variables.accountId},
    });
  } else {
    result = await axiosInstance.get<Data, AxiosResponse<Data>>(
      `/frontend/files/${variables.accountId}${appendedQueryParams}`
    );
  }
  return result.data;
};

export const useGetUserFilesQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(
    ["user", "files", variables.tab, variables.accountId],
    getUserFiles,
    variables,
    options
  );
};
