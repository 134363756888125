import React, {FunctionComponent, useCallback, useEffect} from "react";
import {Dialog, Link} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {CloseDialogButton, Container, ImgReliefPlan, Text, Button, Colors} from "../../../../bounce-ui/consumer";
import {useAmplitude} from "../../../../contexts";
import CancelPaymentPlanDialog from "./CancelPaymentPlanDialog";
import {FormattedMessage} from "react-intl";
import {Pages} from "../../../../bi-events/enum";
import {useWebchat} from "../../../../components/webchat/components/useWebchat";

export type DelayPaymentDialogResult = "delayed" | "cancelPaymentPlan" | "none";

interface IPreCancelPlanDialog {
  accountId: string;
  activePaymentPlanId: string;
}

const PreCancelPlanDialog: FunctionComponent<IPreCancelPlanDialog & NiceModalHocProps> = NiceModal.create(
  ({accountId, activePaymentPlanId}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const modal = useModal();
    const {openChat} = useWebchat();

    const closeHandler = useCallback((): void => {
      modal.resolve("none");
      modal.hide();
    }, []);
    const handleClickProceed = () => {
      pageClickEvent({page: Pages.CANCEL_PAYMENT_INTRO, cta: "talk_to_expert"});
      closeHandler();
      openChat({});
    };

    const openCancelPlanDialog = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.CANCEL_PAYMENT_INTRO, cta: "cancel_plan"});
      closeHandler();
      await NiceModal.show<boolean>(CancelPaymentPlanDialog, {
        accountId: accountId,
        activePaymentPlanId,
      });
    }, [accountId]);

    useEffect(() => {
      pageViewEvent({page: Pages.CANCEL_PAYMENT_INTRO});
    }, []);

    return (
      <Dialog
        open={modal.visible}
        onClose={closeHandler}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <CloseDialogButton onClose={closeHandler} />
        <Container sx={{gap: "15px", overflow: "auto"}}>
          <Container sx={{gap: "15px"}}>
            <ImgReliefPlan />
            <Text
              translateId={"needTimeOrMoreAffordableOptions"}
              size={22}
              fontWeight={"bold"}
              sx={{width: "100%", textAlign: "start"}}
            />
            <Container sx={{gap: "15px", alignItems: "start"}}>
              <Text translateId={"ourTemporaryRelief"} />
              <Text translateId={"notOnlyWillYouBenefit"} />
              <Text translateId={"talkToOneOfOurExpertsToday"} />
            </Container>
          </Container>
          <Container sx={{gap: "15px"}}>
            <Button onClick={handleClickProceed}>
              <FormattedMessage id={"talkToAnExpert"} />
            </Button>
            <Text size={14} translateId={"stillWantTo"}>
              <Link onClick={openCancelPlanDialog} sx={{textDecoration: "none"}}>
                <Text
                  size={14}
                  fontWeight={"bold"}
                  translateId={"cancelMyPlan"}
                  sx={{color: Colors.primary._800, display: "inline", cursor: "pointer"}}
                />
              </Link>
              .
            </Text>
          </Container>
        </Container>
      </Dialog>
    );
  }
);

export default PreCancelPlanDialog;
