import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {PaymentPlanOffer} from "../../../declerations/server/payment_plan_models";

type Variables = {
  abstractPaymentPlanName: string;
  accountId?: string;
  customInstallmentSize?: number;
};

type Data = PaymentPlanOffer;

export const getPaymentPlanOfferByPlanId = async (variables: Variables): Promise<Data> => {
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(
    `/frontend/payment_plans/get-payment-plan-offer/${variables.accountId}/${variables.abstractPaymentPlanName}`,
    {
      params: {
        ...(variables.customInstallmentSize && {custom_installment_size: Math.round(variables.customInstallmentSize)}),
      },
    }
  );
  result.data.account_id = String(result.data.account_id);
  return result.data;
};

export const useGetPaymentPlanOfferByPlanIdQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  return useBaseQuery<Data, Variables>(
    ["plan", "account", variables.accountId, "plan-offer", variables.abstractPaymentPlanName],
    getPaymentPlanOfferByPlanId,
    variables,
    options
  );
};
