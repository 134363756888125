import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {BaseQueryOptions, BaseUseQueryResult, useBaseQuery} from "../base/useBaseQuery";
import {UserDashboard} from "../../../declerations/server/user_models";
import {useUser} from "../../../contexts";

type Variables = {account_id: string; isAgent?: boolean; payment_plan_id?: string};

type Data = UserDashboard;

export const getDashboard = async (variables: Variables): Promise<Data> => {
  let result;
  // solves the problem of payment_plan_id doesn't arrive from the BE yes
  if (!variables.payment_plan_id) {
    // @ts-ignore
    return undefined;
  }

  if (variables.isAgent) {
    result = await axiosInstance.get<Data, AxiosResponse<Data>>(`/backoffice/dashboard/${variables.payment_plan_id}`);
  } else {
    result = await axiosInstance.get<Data, AxiosResponse<Data>>(
      `/frontend/payment_plans/dashboard/${variables.payment_plan_id}`
    );
  }

  return result.data;
};

export const useGetDashboard = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getDashboard, options);

export const useGetDashboardQuery = (
  variables: Variables,
  options?: BaseQueryOptions<Data, Variables>
): BaseUseQueryResult<Data> => {
  const {isAgent} = useUser();
  return useBaseQuery<Data, Variables>(
    ["account", variables.account_id, "dashboard", variables.payment_plan_id],
    getDashboard,
    {isAgent, ...variables},
    {...options, staleTime: 10000}
  );
};
