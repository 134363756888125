import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  abstractPaymentPlanName: string;
  startDate: string;
  accountId: string;
};

type Data = string;

export const getPaymentPlanEndDateFromPreset = async (variables: Variables): Promise<Data> => {
  const result = await axiosInstance.get<Data, AxiosResponse<Data>>(
    `/frontend/payment_plans/end-date/${variables.accountId}/${variables.abstractPaymentPlanName}`,
    {params: {start_date: variables.startDate}}
  );
  return result.data;
};

export const useGetPaymentPlanEndDateFromPreset = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => useBaseMutation(getPaymentPlanEndDateFromPreset, options);
