import {axiosInstance} from "../../ServerApiManager";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  userId: string;
  accountId: string;
  paymentPlanId: number;
  principal: string;
};
type Data = boolean;

export const updatePaymentPlanPrincipal = async (variables: Variables): Promise<Data> => {
  await axiosInstance.patch(`/backoffice/update_principal/${variables.userId}/${variables.accountId}`, {
    payment_plan_id: variables.paymentPlanId,
    principal: variables.principal,
  });
  return true;
};

export const useUpdatePaymentPlanPrincipal = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updatePaymentPlanPrincipal, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user", variables.userId, "timeline"],
  });
