import {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {policeRuleTypeInfoMap} from "../../../../../declerations/server/police_models";
import dayjs, {Dayjs} from "dayjs";
import {arbitraryCommunicationPolice} from "../../../../../apis/requests/backoffice/arbitraryCommunicationPolice";
import {CommunicationType} from "declerations/server";
import {ISO_FORMAT} from "./common";
import {Container, Text} from "../../../../../bounce-ui/backoffice";

interface IArbitraryPoliceRules {
  userId: string;
  contact: string;
  body: string;
  date: Dayjs;
  communicationType: CommunicationType;
}
export const ArbitraryPoliceRules: FunctionComponent<IArbitraryPoliceRules> = ({
  userId,
  contact,
  body,
  date,
  communicationType,
}) => {
  const intl = useIntl();
  const dateInUtcStr = dayjs(date).utc(false).format(ISO_FORMAT);
  const [policeBrokenRules, setPoliceBrokenRulesString] = useState<string[]>([]);
  useEffect(() => {
    (async function () {
      const policeResult = await arbitraryCommunicationPolice({
        user_id: userId,
        content: {subject: body, body: body, communication_type: communicationType},
        contact: contact,
        date: dateInUtcStr,
      });

      if (policeResult.broken_rules.length > 0) {
        setPoliceBrokenRulesString(
          policeResult.broken_rules.map((broken_rule) =>
            intl.formatMessage({id: policeRuleTypeInfoMap.get(broken_rule)?.labelKey})
          )
        );
      }
    })();
  }, []);

  return policeBrokenRules.length > 0 ? (
    <Container sx={{padding: "10px 0px", alignItems: "start"}}>
      <Text translateId={"brokenPoliceRulesString"} size={14} />
      <Container sx={{gap: "0px", alignItems: "start"}}>
        {policeBrokenRules.map((policeBrokenRule) => {
          return (
            <Text size={14} fontWeight={"bold"}>
              <li>{policeBrokenRule}</li>
            </Text>
          );
        })}
      </Container>
    </Container>
  ) : null;
};
