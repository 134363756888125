import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";

type Variables = {
  userId: string;
  language?: string;
  dateOfBirth?: string;
  phoneCallPreferences?: boolean;
  smsPreferences?: boolean;
  emailPreferences?: boolean;
  mailPreferences?: boolean;
};
type Data = number;

export const updateUser = async (variables: Variables): Promise<Data> => {
  const result = await axiosInstance.patch<string, AxiosResponse<number>>(`/backoffice/users/${variables.userId}`, {
    preferred_language: variables?.language,
    date_of_birth: variables?.dateOfBirth,
    phone_call_preferences: variables?.phoneCallPreferences,
    sms_preferences: variables?.smsPreferences,
    email_preferences: variables?.emailPreferences,
    mail_preferences: variables?.mailPreferences,
  });
  return result.data;
};

export const useUpdateUser = (options?: BaseMutationOptions<Data, Variables>): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateUser, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
