import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useChangePaymentMethod} from "../../../../apis/requests/payments/changePaymentMethod";
import {PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import {useAmplitude, useTask, useUser} from "../../../../contexts";
import {openPaymentFailedDialog, PaymentContext} from "../../../../pages/account/payment/utils";
import {useGetDashboardQuery} from "../../../../apis/requests/plans";
import {Container, CloseDialogButton} from "bounce-ui/consumer";
import PaymentMethod from "./PaymentMethod";
import {Pages} from "../../../../bi-events/enum";

export type IChangePaymentMethod = {
  accountId: string;
  title: string;
  onPaymentSuccess?: () => void;
  onPaymentFailed?: () => void;
  activePaymentPlanId: string;
};

const ChangePaymentMethodDialog: FunctionComponent<IChangePaymentMethod & NiceModalHocProps> = NiceModal.create(
  ({accountId, title, onPaymentSuccess, onPaymentFailed, activePaymentPlanId}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const modal = useModal();
    const {isAgent} = useUser();
    const {getUserId} = useTask();
    const [buttonTouched, setButtonTouched] = useState(false);

    const userDashboard = useGetDashboardQuery(
      {
        account_id: accountId,
        isAgent,
        payment_plan_id: activePaymentPlanId,
      },
      {enabled: !!activePaymentPlanId}
    );

    const closeHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, []);

    const changePaymentMethod = useChangePaymentMethod({refetchType: "active"});

    const changePaymentMethodHandler = useCallback(
      async (paymentType: PaymentType, paymentDetails: PaymentDetails): Promise<void> => {
        let error;
        try {
          const result = await changePaymentMethod.mutateAsync({
            ...paymentDetails,
            account_id: accountId,
            payment_plan_id: activePaymentPlanId,
            isAgent,
            user_id: getUserId(),
          });
          if (result.success) {
            paymentSuccessHandler();
          } else {
            error = result.error_message || "Payment failed";
            openPaymentFailedDialog(error);
            setButtonTouched(false);
          }
        } catch (e) {
          paymentFailedHandler();
        } finally {
          pageClickEvent({
            page: Pages.MANAGE_PAYMENT_METHOD,
            cta: "save_changes",
            data: {consent: true, payment_method: paymentType, error},
          });
        }
      },
      [accountId, changePaymentMethod, userDashboard.data]
    );

    const paymentSuccessHandler = useCallback((): void => {
      onPaymentSuccess?.();

      modal.resolve(true);
      modal.hide();
    }, [onPaymentSuccess]);

    const paymentFailedHandler = useCallback((): void => {
      setButtonTouched(false);
      onPaymentFailed?.();
    }, [onPaymentFailed]);

    useEffect(() => {
      pageViewEvent({page: Pages.MANAGE_PAYMENT_METHOD});
    }, []);

    return (
      <PaymentContext.Provider value={{buttonTouched, setButtonTouched, isLoading: changePaymentMethod.isLoading}}>
        <Dialog
          open={modal.visible}
          onClose={closeHandler}
          TransitionProps={{
            onExited: () => modal.remove(),
          }}
        >
          <Container sx={{alignItems: "start", overflow: "auto"}}>
            <CloseDialogButton onClose={closeHandler} />
            <PaymentMethod
              accountId={accountId}
              paymentHandler={changePaymentMethodHandler}
              title={title}
              submitTitle={"saveChanges"}
              currentMethodType={userDashboard.data?.payment_method_type}
              page={Pages.MANAGE_PAYMENT_METHOD}
            />
          </Container>
        </Dialog>
      </PaymentContext.Provider>
    );
  }
);

export default ChangePaymentMethodDialog;
