import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {UserFlags} from "../../../constants/enums";

type Variables = {
  userId: string;
  turnOffFlagTypes: Array<UserFlags>;
  turnOnFlagTypes: Array<UserFlags>;
  value: boolean;
};
type Data = boolean;

export const updateUserFlagStatus = async (variables: Variables): Promise<Data> => {
  await axiosInstance.post<string, AxiosResponse<string>>(`/backoffice/change_user_flag_status/${variables.userId}`, {
    turn_off_flag_types: variables.turnOffFlagTypes,
    turn_on_flag_types: variables.turnOnFlagTypes,
  });
  return true;
};

export const useUpdateUserFlagStatus = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateUserFlagStatus, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
