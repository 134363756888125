import React, {FunctionComponent, ReactNode} from "react";
import {PaymentDetails} from "../../../../declerations/server";
import {PaymentType} from "../../../../declerations/enums";
import PaymentTabPanel from "./PaymentTabPanel";
import {Container, ImgBillingTree, ImgPci} from "../../../../bounce-ui/consumer";

interface PaymentPageProps {
  paymentDetails?: ReactNode;
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails) => void;
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  paymentDate?: string;
  featureFlagFirstPaymentDate?: boolean;
}

export const PaymentsImages = () => (
  <Container fullWidth row sx={{justifyContent: "center", gap: "25px", padding: "10px"}}>
    <ImgBillingTree />
    <ImgPci />
  </Container>
);

const PaymentPage: FunctionComponent<PaymentPageProps> = ({
  onCardSubmit,
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
}: PaymentPageProps) => {
  return (
    <Container fullWidth>
      <PaymentTabPanel
        onCardSubmit={onCardSubmit}
        onAchSubmit={onAchSubmit}
        submitLabelId={submitLabelId}
        termsAndConditionsId={termsAndConditionsId}
        termsAndConditionsTextId={termsAndConditionsTextId}
      />
    </Container>
  );
};
export default PaymentPage;
