import React from "react";
import {EnumInfo, SelectOption} from "../declerations/internal";

const InputUtils = {
  inputRemoveWhitespaces: function (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/\s/g, "");
  },

  inputRemoveNonDigits: function (e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9]/gi, "");
  },

  inputRemoveNonIntegerCharacters: function (e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9+-]/gi, "");
  },

  inputRemoveNonFloatCharacters: function (e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    e.target.value = value.replace(/[^0-9+\-.]/gi, "");
  },

  getEnumSelectOption: function <T extends string>(value: T, enumInfo?: EnumInfo): SelectOption<T> {
    return {
      value: value,
      label: enumInfo?.labelKey || value,
    };
  },

  getEnumSelectOptions: function <T extends string>(enumInfoMap: Map<T, EnumInfo>): SelectOption<T>[] {
    return Array.from(enumInfoMap).map(([value, enumInfo]) => this.getEnumSelectOption(value, enumInfo));
  },

  prettierSnakeCase: function (snakeCaseString: string): string {
    return snakeCaseString
      .replaceAll("_", " ")
      .split(" ")
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
      .join(" ");
  },

  prettierSnakeCaseSentence: function (snakeCaseString: string): string {
    const prettyString = snakeCaseString.replaceAll("_", " ").split(" ").join(" ");
    return prettyString.charAt(0).toUpperCase() + prettyString.slice(1).toLowerCase();
  },

  capitalizeString: function (str: string): string {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  },

  containsHyphenOrSpace: function (str: string): boolean {
    const regex = /[- ]/; // This regular expression matches either a hyphen or a space
    return regex.test(str);
  },

  containsOnlyDigits: function (str: string): boolean {
    return /^\d+$/.test(str);
  },

  truncateAfterTwoDigits: function (amount: number): number {
    return Math.trunc(amount * 100) / 100;
  },
};

export default InputUtils;
