import React, {FunctionComponent, useCallback, useEffect, useMemo} from "react";
import {Dialog, Link} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {CloseDialogButton, Container, ImgPaymentFailed, Text, Button} from "../../../../bounce-ui/consumer";
import {useAmplitude, useUi, useUser} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";
import {FormattedMessage} from "react-intl";
import {useWebchat} from "../../../../components/webchat/components/useWebchat";
import InputUtils from "../../../../helpers/InputUtils";
import {useGetContactDetailQuery} from "../../../../apis/requests/user/getConatctDetails";

interface IPaymentFailedDialog {
  error: string;
}

const PaymentFailedDialog: FunctionComponent<IPaymentFailedDialog & NiceModalHocProps> = NiceModal.create(({error}) => {
  const {pageViewEvent, pageClickEvent} = useAmplitude();
  const modal = useModal();
  const {uiSettings} = useUi();
  const contactDetail = useGetContactDetailQuery({});
  const email = useMemo(() => {
    if (contactDetail?.data) {
      return contactDetail?.data.email;
    }
  }, [contactDetail.data]);
  const {openChat} = useWebchat();
  const {isAgent} = useUser();

  const handleOpenChat = () => {
    pageClickEvent({page: Pages.PAYMENT_FAILED_DIALOG, cta: "got_it"});
    closeHandler();
    if (!isAgent && email) {
      openChat({
        data: {
          email,
          name: uiSettings?.user_name,
          query: "Hi! I'm having trouble processing a payment. Can you assist me, please?",
        },
      });
    }
  };

  const closeHandler = useCallback((): void => {
    modal.resolve(false);
    modal.hide();
  }, []);

  useEffect(() => {
    pageViewEvent({page: Pages.PAYMENT_FAILED_DIALOG});
  }, []);

  return (
    <Dialog
      open={modal.visible}
      onClose={closeHandler}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <CloseDialogButton onClose={closeHandler} />
      <Container sx={{padding: "0px 10px", overflow: "auto", minHeight: "520px", justifyContent: "space-around"}}>
        <Container sx={{gap: "25px", alignItems: "start"}}>
          <Container fullWidth>
            <ImgPaymentFailed />
          </Container>
          <Text translateId={"paymentUnsuccessful"} size={18} fontWeight={"bold"} sx={{width: "100%"}} />
          <Text
            translateId={"reasonError"}
            size={14}
            values={{error: InputUtils.prettierSnakeCaseSentence(error)}}
            fontWeight={"bold"}
          />
          <Text size={14} translateId={"youCanTryAgainWithADifferent"}>
            <Link href={"#"} onClick={handleOpenChat}>
              <Text
                size={14}
                translateId={"chatWithAnExpert"}
                fontWeight={"bold"}
                color={"secondary"}
                sx={{textTransform: "lowercase", display: "inline"}}
              />
            </Link>
            <Text translateId={"toFindTheRightSolution"} size={14} sx={{display: "inline"}} />
          </Text>
        </Container>
        <Button onClick={closeHandler}>
          <FormattedMessage id="gotItThanks" />
        </Button>
      </Container>
    </Dialog>
  );
});

export default PaymentFailedDialog;
