import {axiosInstance} from "../../ServerApiManager";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {RepresentationType} from "../../../declerations/server/representation_models";

type Variables = {
  type: RepresentationType;
  name: string;
};
type Data = boolean;

export const addRepresentationCompanies = async (variables: Variables): Promise<Data> => {
  await axiosInstance.post(`/backoffice/representation-companies`, {type: variables.type, name: variables.name});
  return true;
};

export const useAddRepresentationCompanies = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(addRepresentationCompanies, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["representation-companies"],
  });
};
