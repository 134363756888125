import * as React from "react";
import {GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import {DataGridStyled} from "./style";

interface IDataTable {
  rows: any;
  columns: GridColDef[];
  selected?: GridRowSelectionModel;
  setSelected?: (selected: GridRowSelectionModel) => void;
  checkboxSelection?: boolean;
  rowHeight?: number;
}

export const DataTable = ({rows, columns, checkboxSelection, selected, setSelected, rowHeight}: IDataTable) => {
  const handleCellClick = (params: any) => {
    if (setSelected && (!params.field || params.field === "_check_")) {
      setSelected(params.rowIds);
    }
  };

  return (
    <DataGridStyled
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
        if (setSelected) setSelected(newSelectionModel);
      }}
      checkboxSelection={checkboxSelection}
      rowSelectionModel={selected}
      onCellClick={handleCellClick}
      disableRowSelectionOnClick
      rowHeight={rowHeight}
      sx={{width: "100%"}}
    />
  );
};
