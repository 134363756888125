import React, {FunctionComponent, useCallback} from "react";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useUpdateEffect} from "react-use";
import {Typography} from "@mui/material";
import {Colors, Container, NoticeDialog, ButtonTypes} from "bounce-ui/backoffice";

export type AreYouSureDialogProps = {
  onConfirm: (x: boolean) => void;
  headerTextId?: string;
  contentTextId?: string;
  confirmTextId: string;
  handlebars?: Record<string, string>;
  children?: React.ReactNode;
  confirmButtonType?: ButtonTypes;
};

const AreYouSureDialog: FunctionComponent<AreYouSureDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onConfirm, headerTextId, contentTextId, confirmTextId, handlebars, children}) => {
    const modal = useModal();
    const {pathname} = useLocation();

    const confirmHandler = useCallback((): void => {
      onConfirm(true);
      modal.resolve(true);
      modal.hide();
    }, [onConfirm, modal]);

    const cancelHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, [modal]);

    useUpdateEffect(() => {
      cancelHandler();
    }, [pathname]);

    return (
      <NoticeDialog
        title={headerTextId || "Are You Sure?"}
        buttonText={confirmTextId}
        onClose={cancelHandler}
        onClick={confirmHandler}
        secondaryButtonText={"Cancel"}
        onClickSecondary={cancelHandler}
        isError
      >
        <Container fullWidth>
          {children}
          {contentTextId && (
            <Typography
              variant={"caption"}
              component={"div"}
              sx={{
                whiteSpace: "break-spaces",
                color: Colors.grey._800,
                lineHeight: "16px",
                fontSize: "14px",
                maxWidth: "400px",
              }}
            >
              <FormattedMessage id={contentTextId} values={handlebars} />
            </Typography>
          )}
        </Container>
      </NoticeDialog>
    );
  }
);

export default AreYouSureDialog;
