import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {AccountFlags} from "../../../constants/enums";
import {Comment} from "declerations/server/comment_models";

type Variables = {
  userId: string;
  accountFlags?: Map<AccountFlags, boolean>;
  accountId: string;
  accountComments: Comment[];
};
type Data = boolean;

export const updateAccount = async (variables: Variables): Promise<Data> => {
  await axiosInstance.patch<string, AxiosResponse<number>>(`/backoffice/accounts/${variables.accountId}`, {
    account_flags: variables.accountFlags ? Object.fromEntries(variables.accountFlags) : {},
    comments: variables.accountComments,
  });
  return true;
};

export const useUpdateAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> =>
  useBaseMutation(updateAccount, {
    ...options,
    invalidateQueriesKeyFn: (data, variables) => ["user"],
  });
