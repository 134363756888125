import React, {useCallback, useContext, useMemo, useState} from "react";
import {Checkbox, FormControl, Link, MenuItem, Select} from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import PaymentTabPanel from "../../../../pages/account/payment/components/PaymentTabPanel";
import {PaymentMethodTypes, PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import {useGetLenderDetailsQuery} from "apis/requests/lender/getLenderDetails";
import {useUi} from "contexts/UiContext";
import {PaymentContext} from "../../../../pages/account/payment/utils";
import {Colors, Container, LoadingButton, Text} from "../../../../bounce-ui/consumer";
import {mapMethodToValue, PaymentMethodType} from "../../../../pages/account/account-dashboard/component-v2/utils";
import TermsAndConditionsDialog from "../../../../pages/account/payment/components/TermsAndConditionsDialog";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";

export type IPaymentMethod = {
  accountId: string;
  lastDigitsCard?: string | undefined;
  title?: string;
  submitTitle?: string;
  currentMethodType?: PaymentMethodTypes;
  paymentHandler: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  disable?: boolean;
  page?: Pages;
  termsAndConditionsId?: string;
};

const PaymentMethod = ({
  accountId,
  lastDigitsCard,
  title,
  paymentHandler,
  submitTitle = "payItNow",
  disable = false,
  currentMethodType,
  page,
  termsAndConditionsId = "paymentTermsAndConditions",
}: IPaymentMethod) => {
  const {pageClickEvent} = useAmplitude();
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>("current");
  const {uiSettings} = useUi();
  const {buttonTouched, isLoading} = useContext(PaymentContext);

  const lenderDetails = useGetLenderDetailsQuery(
    {lender_name: uiSettings?.lender_id || ""},
    {enabled: !!uiSettings?.lender_id}
  );

  const termsAndConditionsHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      if (page) {
        pageClickEvent({page, cta: "t_c"});
      }
      event.preventDefault();
      NiceModal.show(TermsAndConditionsDialog, {
        termsAndConditionsTextId: lenderDetails?.data?.disclaimers.payment_terms_and_conditions,
      });
    },
    [lenderDetails.data]
  );

  const paymentMethodOption = useMemo(() => {
    const options = mapMethodToValue(currentMethodType, lastDigitsCard);
    if (!Object.keys(options).includes("current")) {
      setPaymentMethod("change");
    }
    return options;
  }, []);

  return (
    <>
      <Container fullWidth sx={{alignItems: "start"}}>
        {title ? (
          <Text size={22} fontWeight={"bold"} translateId={title} sx={{marginLeft: "6px"}} />
        ) : (
          <Text
            translateId={"paymentMethod"}
            size={12}
            fontWeight={"medium"}
            sx={{textTransform: "uppercase", color: Colors.grey._500}}
          />
        )}

        {paymentMethodOption && lastDigitsCard && (
          <FormControl variant="outlined" sx={{width: "300px"}}>
            <Select
              value={paymentMethod}
              onChange={(event: any) => setPaymentMethod(event.target.value)}
              sx={{
                height: "40px",
                padding: "0px 20px",
              }}
            >
              {Object.keys(paymentMethodOption).map((method) => (
                <MenuItem key={method} value={method}>
                  {paymentMethodOption[method]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {(!lastDigitsCard || paymentMethod === "change") && (
          <PaymentTabPanel
            onCardSubmit={paymentHandler}
            onAchSubmit={paymentHandler}
            submitLabelId={submitTitle}
            termsAndConditionsId={termsAndConditionsId}
            termsAndConditionsTextId={lenderDetails.data?.disclaimers.payment_terms_and_conditions}
            onDashboardDialog={true}
            currAccountId={accountId}
          />
        )}
      </Container>
      {lastDigitsCard && paymentMethod === "current" && (
        <Container>
          <Container row sx={{alignItems: "start"}}>
            <Checkbox
              checked={checkedTerms}
              sx={{padding: 0, mr: 0.5}}
              onClick={() => setCheckedTerms(!checkedTerms)}
            />
            <Text
              translateId={termsAndConditionsId}
              size={12}
              values={{
                termsAndConditions: (
                  <Link href={"#"} onClick={termsAndConditionsHandler}>
                    <Text
                      size={12}
                      translateId={"termsAndConditions"}
                      fontWeight={"bold"}
                      color={"secondary"}
                      sx={{display: "inline"}}
                    />
                  </Link>
                ),
                collectionLabel: uiSettings?.collection_label || "",
              }}
            />
          </Container>
          <LoadingButton
            id={submitTitle}
            disabled={!checkedTerms || disable || buttonTouched}
            onClick={paymentHandler}
            loading={isLoading}
          />
        </Container>
      )}
    </>
  );
};

export default PaymentMethod;
