import {Badge, Tooltip} from "@mui/material";
import {Link, Text} from "../../../../../bounce-ui/backoffice";
import React from "react";
import {viewUserFiles} from "../../../../../apis/requests/files";

export const FileName = ({fileName, fileId}: {fileName: string; fileId: string}) => {
  const handleViewFile = async (fileId: string, fileName: string) => {
    await viewUserFiles({fileId, isAgent: true});
  };

  const isViewable = [".pdf", ".html", ".png", "jpeg", "jpg", "svg", "svg+xml", "tif", "tiff"].some((fileType) =>
    fileName.endsWith(fileType)
  );
  return (
    <Tooltip title={fileName}>
      {isViewable ? (
        <Link onClick={() => handleViewFile(fileId, fileName)}>
          <Text
            size={14}
            fontWeight={"bold"}
            sx={{cursor: "pointer", textDecoration: "underline", overflow: "visible", whiteSpace: "normal"}}
          >
            {fileName}
          </Text>
        </Link>
      ) : (
        <Badge>
          <Text size={14} fontWeight={"bold"} sx={{overflow: "visible", whiteSpace: "normal"}}>
            {fileName}
          </Text>
        </Badge>
      )}
    </Tooltip>
  );
};
