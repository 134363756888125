export enum Pages {
  // Login
  USER_IDENTIFIER = "user_identifier",
  CONTEXT_CONTACT = "context_contact",
  ANON_CONTACT = "anon_contact",
  OTP = "otp",

  // Overview
  ACCOUNTS = "accounts",

  // Onboarding
  ACCOUNT = "account",
  ONBOARDING_INFORMATION = "onboarding_information",
  PLAN_TYPE = "plan_type",
  PLAN_FREQUENCY = "plan_frequency",
  PLAN_AMOUNT = "plan_amount",
  PLAN_SUMMARY = "plan_summary",
  LEGAL_NOTICE = "legal_notice",
  PAYMENT_SUCCESS = "payment_success",
  CALENDAR_REMINDERS = "calendar_reminders",
  T_C = "t_c",
  PAYMENT_SUMMARY = "payment_summary",
  PARTIAL_PAYMENT = "partial_payment",
  ONE_TIME_PAYMENT = "one_time_payment",
  CHOOSE_DATE = "choose_date",
  DISPUTE_NOTE = "dispute_note",
  DISPUTE_CONTACT = "dispute_contact",
  DISPUTE_REASON = "dispute_reason",
  DISPUTE_REVIEW = "dispute_review",
  DISPUTE_HOW_TO_PROCEED = "dispute_how_to_proceed",
  FRAUD_NOTE = "fraud_note",
  DISPUTE_FILE = "dispute_file",
  DISPUTE_SUCCESS = "dispute_success",
  BLOCKED_ACCOUNT_NOTE = "blocked_account_note",
  GET_HELP = "get_help",
  MANAGE_CONTACT = "manage_contact",
  VALIDATION_NOTICE = "validation_notice",
  HARDSHIP_PLAN_DIALOG = "hardship_plan_dialog",
  HARDSHIP_INTRO = "hardship_intro",
  HARDSHIP_LOADER = "hardship_loader",
  HARDSHIP_PLANS_VIEW = "hardship_plans_view",
  PAYMENT_FAILED_DIALOG = "payment_failed_dialog",

  // Consolidated payment plan
  CONSOLIDATED_PLAN = "consolidated_plan",

  // Global
  CHAT = "chat",
  CHAT_BANNER = "chat_banner",
  MENU = "menu",

  // App
  DASHBOARD = "dashboard",
  MANAGE_PAYMENT = "manage_payment",
  ONE_TIME_PAYMENT_DIALOG = "one_time_payment_dialog",
  MANAGE_PAYMENT_METHOD = "manage_payment_method",
  CANCEL_PAYMENT_INTRO = "cancel_payment_intro",
  CANCEL_PLAN = "cancel_plan",
  SWITCH_TO_AUTOPAY = "switch_to_autopay",
  ACCOUNT_DETAILS = "account_details",
  LEAVE_REVIEW = "leave_review",

  // Guest Payment Link
  EXPIRED_PAYMENT_LINK = "expired_payment_link",
  GUEST_CHECKOUT = "guest_checkout",
}
