import React, {FunctionComponent, useCallback, useContext, useMemo} from "react";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem} from "@mui/material";
import {Container, Text, LoadingButton, TextField, Link} from "../../../../bounce-ui/consumer";
import {Controller, useForm} from "react-hook-form";
import {EMAIL_REGEX} from "../../../../constants/regex";
import {FormattedMessage, useIntl} from "react-intl";
import {bankAccountTypeInfoMap} from "../../../../constants/enums";
import {SelectOption} from "../../../../declerations/internal";
import InputUtils from "../../../../helpers/InputUtils";
import {BankAccountType, PaymentType} from "../../../../declerations/enums";
import {PaymentDetails} from "../../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import {useAmplitude, useUi} from "../../../../contexts";
import {PaymentContext} from "../utils";
import {PaymentsImages} from "./PaymentPage";
import LargePaymentSplitLabel from "./LargePaymentsSplitLabel";
import {Pages} from "../../../../bi-events/enum";
import {useGetUserQuery} from "../../../../apis/requests/user/getUser";

interface AchPaymentFormProps {
  onAchSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isChangePaymentMethod: boolean;
  disable?: boolean;
  primaryEmail?: string;
}

type FormValues = {
  accountType: BankAccountType;
  name: string;
  accountNumber: string;
  routingNumber: string;
  email: string;
  confirmTerms: boolean;
};

const AchPaymentForm: FunctionComponent<AchPaymentFormProps> = ({
  onAchSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isChangePaymentMethod,
  primaryEmail,
}: AchPaymentFormProps) => {
  const {pageClickEvent} = useAmplitude();
  const intl = useIntl();
  const {uiSettings} = useUi();
  const {disableACHAccountOwnerPrePopulation} = useContext(PaymentContext);
  const {data: user} = useGetUserQuery({});

  const {handleSubmit, control, formState} = useForm<FormValues>({
    defaultValues: {name: disableACHAccountOwnerPrePopulation ? "" : user?.name},
  });
  const {isValid} = formState;
  const {buttonTouched, setButtonTouched, isLoading, disableButton, showLargeSplitLabel} = useContext(PaymentContext);

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    const paymentDetails = {
      ach: {
        account_type: data.accountType,
        account_name: data.name,
        account_number: data.accountNumber.replace(/\s/g, ""),
        ABA: data.routingNumber.replace(/\s/g, ""),
        state: "",
        zip_code: "",
      },
      email: data.email || undefined,
    };
    setButtonTouched(true);
    onAchSubmit("ACH", paymentDetails, isChangePaymentMethod);
  });

  const termsAndConditionsHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    pageClickEvent({page: Pages.PAYMENT_SUMMARY, cta: "t_c"});
    event.preventDefault();
    NiceModal.show(TermsAndConditionsDialog, {termsAndConditionsTextId});
  }, []);

  const bankAccountTypes = useMemo<SelectOption<BankAccountType>[]>(
    () => InputUtils.getEnumSelectOptions(bankAccountTypeInfoMap),
    []
  );

  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit}>
      <Container sx={{gap: "30px"}}>
        {showLargeSplitLabel && <LargePaymentSplitLabel />}
        <Controller
          name="accountType"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue={bankAccountTypes[0].value}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"bankAccountType"}
                error={invalid}
                helperText={error?.message}
                select
              >
                {bankAccountTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <FormattedMessage id={option.label} />
                  </MenuItem>
                ))}
              </TextField>
            );
          }}
        />

        <Controller
          name="name"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"accountOwnerName"}
                type="text"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 50,
                }}
              />
            );
          }}
        />

        <Controller
          name="accountNumber"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"bankAccountNumber"}
                type="text"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 20,
                }}
              />
            );
          }}
        />
        <Controller
          name="routingNumber"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"bankRoutingNumber"}
                type="text"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 30,
                }}
              />
            );
          }}
        />
        <Controller
          name="email"
          rules={{
            pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
          }}
          control={control}
          defaultValue={primaryEmail}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"emailAddressForReceipts"}
                type="email"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 50,
                }}
              />
            );
          }}
        />
        <PaymentsImages />
        <Controller
          name="confirmTerms"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue={false}
          render={({field: {ref, value, ...field}, fieldState: {invalid, error}}) => {
            return (
              <FormControl required error={invalid}>
                <FormControlLabel
                  control={<Checkbox {...field} inputRef={ref} checked={value} sx={{padding: 0, mr: 0.5}} />}
                  label={
                    <Text
                      translateId={termsAndConditionsId || "paymentTermsAndConditions"}
                      size={12}
                      values={{
                        termsAndConditions: (
                          <Link href={"#"} onClick={termsAndConditionsHandler}>
                            <Text
                              size={12}
                              translateId={"termsAndConditions"}
                              fontWeight={"bold"}
                              color={"secondary"}
                              sx={{display: "inline"}}
                            />
                          </Link>
                        ),
                        collectionLabel: uiSettings?.collection_label || "",
                      }}
                    />
                  }
                  sx={{alignItems: "start"}}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <LoadingButton
          id={submitLabelId || "confirmPayment"}
          disabled={buttonTouched || disableButton}
          loading={isLoading}
          type={"submit"}
        />
      </Container>
    </form>
  );
};
export default AchPaymentForm;
