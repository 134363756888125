import React, {FunctionComponent, useCallback, useContext, useMemo, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, Link, MenuItem} from "@mui/material";
import {PaymentType} from "../../../../declerations/enums";
import {Controller, useForm} from "react-hook-form";
import {EMAIL_REGEX} from "../../../../constants/regex";
import {useIntl} from "react-intl";
import {range} from "lodash";
import {PaymentDetails} from "../../../../declerations/server";
import NiceModal from "@ebay/nice-modal-react";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import {useAmplitude, useUi} from "../../../../contexts";
import {formatInput, PaymentContext} from "../utils";
import {Container, Text, LoadingButton, TextField} from "../../../../bounce-ui/consumer";
import {PaymentsImages} from "./PaymentPage";
import {useSnackbar} from "notistack";
import FiveCentsVerificationlabel from "./FiveCentsVerificationlabel";
import LargePaymentSplitLabel from "./LargePaymentsSplitLabel";
import {Pages} from "../../../../bi-events/enum";

interface CreditCardPaymentFormProps {
  type: PaymentType;
  onCardSubmit: (paymentType: PaymentType, paymentDetails: PaymentDetails, isChangePaymentMethod: boolean) => void;
  submitLabelId?: string;
  termsAndConditionsId?: string;
  termsAndConditionsTextId?: string;
  isChangePaymentMethod: boolean;
  primaryEmail?: string;
}

type FormValues = {
  name: string;
  cardNumber: string;
  month: string;
  year: string;
  cvv: string;
  zip: string;
  email?: string;
  confirmTerms: boolean;
};

const CreditCardPaymentForm: FunctionComponent<CreditCardPaymentFormProps> = ({
  type,
  onCardSubmit,
  submitLabelId,
  termsAndConditionsId,
  termsAndConditionsTextId,
  isChangePaymentMethod,
  primaryEmail,
}: CreditCardPaymentFormProps) => {
  const {pageClickEvent} = useAmplitude();
  const intl = useIntl();
  const {uiSettings} = useUi();
  const {enqueueSnackbar} = useSnackbar();
  const {handleSubmit, control, formState} = useForm<FormValues>();
  const {isValid} = formState;
  const {buttonTouched, setButtonTouched, isLoading, showFiveCentsLabel, showLargeSplitLabel, disableButton} =
    useContext(PaymentContext);
  const [cardInput, setCardInput] = useState<string>("");
  const handleCardChange = (event: any) => {
    const {value} = event.target;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 16) {
      setCardInput(formatInput(numericValue));
    }
  };

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    const cardNumber = cardInput.replace(/[\s-]/g, "");
    if (cardNumber.length === 16) {
      setButtonTouched(true);
      const paymentDetails = {
        credit_card: {
          card_number: cardNumber,
          cvv: data.cvv,
          address: "",
          card_holder_name: data.name,
          expiration_date: `${data.month}${data.year.substring(2)}`,
          zip_code: data.zip,
        },
        email: data.email || undefined,
      };
      onCardSubmit(type, paymentDetails, isChangePaymentMethod);
    } else {
      enqueueSnackbar(<Text translateId={"creditCardInvalid"} size={14} />, {variant: "error"});
    }
  });
  const termsAndConditionsHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    pageClickEvent({page: Pages.PAYMENT_SUMMARY, cta: "t_c"});
    event.preventDefault();
    NiceModal.show(TermsAndConditionsDialog, {termsAndConditionsTextId});
  }, []);

  const months = useMemo<string[]>(() => ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"], []);
  const currentYear = useMemo<number>(() => new Date().getFullYear(), []);
  const years = useMemo<string[]>(() => range(0, 10).map((v) => `${currentYear + v}`), []);
  return (
    <form autoComplete="off" noValidate onSubmit={onSubmit}>
      <Container sx={{gap: "30px"}}>
        {showFiveCentsLabel && <FiveCentsVerificationlabel type={type} />}
        {showLargeSplitLabel && <LargePaymentSplitLabel />}
        <Controller
          name="name"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue=""
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"nameOnCard"}
                type="text"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 80,
                }}
              />
            );
          }}
        />

        <Controller
          name="cardNumber"
          control={control}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                value={cardInput}
                onChange={handleCardChange}
                inputRef={ref}
                fullWidth
                label={"cardNumber"}
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 80,
                  inputMode: "numeric",
                }}
              />
            );
          }}
        />

        <Container fullWidth row sx={{gap: "30px"}}>
          <Controller
            name="month"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            defaultValue={""}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"month"}
                  error={invalid}
                  helperText={error?.message}
                  select
                >
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            name="year"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            defaultValue={""}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"year"}
                  error={invalid}
                  helperText={error?.message}
                  select
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />
        </Container>

        <Container fullWidth row sx={{gap: "30px"}}>
          <Controller
            name="cvv"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            defaultValue=""
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"cvv"}
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    maxLength: 4,
                    inputMode: "numeric",
                  }}
                />
              );
            }}
          />

          <Controller
            name="zip"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            defaultValue=""
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={"zipCode"}
                  error={invalid}
                  helperText={error?.message}
                  inputProps={{
                    maxLength: 5,
                    inputMode: "numeric",
                  }}
                />
              );
            }}
          />
        </Container>

        <Controller
          name="email"
          rules={{
            pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
          }}
          control={control}
          defaultValue={primaryEmail}
          render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
            return (
              <TextField
                {...field}
                inputRef={ref}
                fullWidth
                label={"emailAddressForReceipts"}
                type="email"
                error={invalid}
                helperText={error?.message}
                inputProps={{
                  maxLength: 50,
                }}
              />
            );
          }}
        />
        <PaymentsImages />
        <Controller
          name="confirmTerms"
          rules={{
            required: intl.formatMessage({id: "requiredField"}),
          }}
          control={control}
          defaultValue={false}
          render={({field: {ref, value, ...field}, fieldState: {invalid, error}}) => {
            return (
              <FormControl required error={invalid}>
                <FormControlLabel
                  control={<Checkbox {...field} inputRef={ref} checked={value} sx={{padding: 0, mr: 0.5}} />}
                  label={
                    <Text
                      translateId={termsAndConditionsId || "paymentTermsAndConditions"}
                      size={12}
                      values={{
                        termsAndConditions: (
                          <Link href={"#"} onClick={termsAndConditionsHandler}>
                            <Text
                              size={12}
                              translateId={"termsAndConditions"}
                              fontWeight={"bold"}
                              color={"secondary"}
                              sx={{display: "inline"}}
                            />
                          </Link>
                        ),
                        collectionLabel: uiSettings?.collection_label || "",
                      }}
                    />
                  }
                  sx={{alignItems: "start"}}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <LoadingButton
          id={submitLabelId || "confirmPayment"}
          disabled={buttonTouched || disableButton}
          loading={isLoading}
          type={"submit"}
        />
      </Container>
    </form>
  );
};
export default CreditCardPaymentForm;
