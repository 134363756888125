import {UserDashboardInstallment} from "../../../../declerations/server";
import {InstallmentStatus, PaymentMethodTypes} from "../../../../declerations/enums";
import {find, sumBy} from "lodash";
import dayjs, {Dayjs} from "dayjs";

export type PaymentMethodType = "current" | "change";

export type PaymentActionType = "manage" | "resolve";

export interface IHighlightedInstallment extends UserDashboardInstallment {
  index: number;
  checked?: boolean;
  editable?: boolean;
}

export interface IInstallmentToPresent {
  minIndex: number;
  maxIndex: number;
}
const REVEAL_ALL_UNDER = 10;
const MIDDLE_INSTALLMENT_INDEX = 2;
const MAX_INSTALLMENT_INDEX = 4;

const findNextInstallmentIndex = (installments: IHighlightedInstallment[], statuses: InstallmentStatus[]): number => {
  return installments.findIndex((installment) => {
    return statuses.includes(installment.installment_status);
  });
};

const findNextUnpaidAfterDate = (
  currDueDate: Dayjs,
  installments: IHighlightedInstallment[]
): IHighlightedInstallment | undefined => {
  return find(
    installments,
    (installment) => installment.installment_status === "unpaid" && dayjs(installment.due_date).isAfter(currDueDate)
  );
};

const findNextPayment = (installments: IHighlightedInstallment[]): IHighlightedInstallment | undefined => {
  const lateInstallments = installments.filter((installment) =>
    ["late", "error"].includes(installment.installment_status)
  );
  if (lateInstallments.length > 1) return;
  const latestLateInstallment = lateInstallments[0];
  if (latestLateInstallment) {
    return findNextUnpaidAfterDate(dayjs(latestLateInstallment.due_date), installments);
  }
  const nextUnpaidInstallment = installments.find((installment) => {
    return installment.installment_status === "unpaid";
  });
  if (nextUnpaidInstallment) {
    return findNextUnpaidAfterDate(dayjs(nextUnpaidInstallment.due_date), installments);
  }
};

const findCurrentUnpaid = (installments: IHighlightedInstallment[]): string => {
  const lateInstallments = installments.filter((installment) =>
    ["late", "error"].includes(installment.installment_status)
  );
  if (lateInstallments.length > 0) return "";
  const installment = installments.find((installment) => installment.installment_status === "unpaid");
  return installment ? installment.id : "";
};

const getInstallmentToPresent = (
  revealAll: boolean,
  installments: IHighlightedInstallment[]
): IInstallmentToPresent => {
  const installmentLength = installments.length - 1;
  const showAfterMiddle = MAX_INSTALLMENT_INDEX - MIDDLE_INSTALLMENT_INDEX;
  if (revealAll || installmentLength < REVEAL_ALL_UNDER) {
    return {minIndex: 0, maxIndex: installmentLength};
  } else {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex < 0) return {minIndex: -1, maxIndex: -1};
    if (nextUnpaidIndex < MIDDLE_INSTALLMENT_INDEX) {
      return {minIndex: 0, maxIndex: MAX_INSTALLMENT_INDEX};
    }
    if (nextUnpaidIndex >= installmentLength - showAfterMiddle) {
      return {minIndex: installmentLength - MAX_INSTALLMENT_INDEX, maxIndex: installmentLength};
    }
    return {
      minIndex: nextUnpaidIndex - MIDDLE_INSTALLMENT_INDEX,
      maxIndex: nextUnpaidIndex + showAfterMiddle,
    };
  }
};

const getHighlightedInstallments = (installments: IHighlightedInstallment[]): number[] => {
  const highlightedInstallments: number[] = [];
  const nextLateIndex = findNextInstallmentIndex(installments, ["late", "error"]);
  if (nextLateIndex !== -1) {
    highlightedInstallments.push(nextLateIndex + 1);
  } else {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex > 0) {
      highlightedInstallments.push(nextUnpaidIndex);
    }
    if (nextUnpaidIndex >= 0) {
      highlightedInstallments.push(nextUnpaidIndex + 1);
    }
  }
  return highlightedInstallments;
};

const getPaymentCardInstallments = (
  installments: IHighlightedInstallment[],
  action: PaymentActionType
): IHighlightedInstallment[] => {
  const highlightedInstallments: IHighlightedInstallment[] = [];
  installments.forEach((installment) => {
    if (["late", "error"].includes(installment.installment_status)) {
      highlightedInstallments.push(installment);
    }
  });
  if (action === "manage") {
    const nextUnpaidIndex = findNextInstallmentIndex(installments, ["unpaid"]);
    if (nextUnpaidIndex >= 0) {
      highlightedInstallments.push(installments[nextUnpaidIndex]);
    }
  }
  if (highlightedInstallments.length > 1) {
    highlightedInstallments[highlightedInstallments.length - 1].editable = true;
  }
  return highlightedInstallments;
};

const sumAmount = (installments: IHighlightedInstallment[]) => {
  return sumBy(installments, (installment) => (installment.checked ? installment.amount_cents : 0));
};

const mapStatusToIcon: Record<string, {icon: string | null; actionText: string | null; statusText: string | null}> = {
  unpaid: {icon: null, actionText: "manage", statusText: "next"},
  paid: {icon: "check", actionText: null, statusText: null},
  late: {icon: "error", actionText: "resolve", statusText: "late"},
  error: {icon: "error", actionText: "resolve", statusText: "late"},
  pending: {icon: "pending", actionText: null, statusText: null},
};

const mapMethodToValue = (
  currentMethodType: PaymentMethodTypes | undefined,
  cardLastDigits: string | undefined
): Record<string, string> => {
  const currentMethodTypeString = currentMethodType === "ach" ? "ACH" : "Debit card";

  return {
    current: `${currentMethodTypeString} (${cardLastDigits})`,
    change: "Change payment method",
  };
};

export {
  findNextPayment,
  findCurrentUnpaid,
  getInstallmentToPresent,
  getHighlightedInstallments,
  getPaymentCardInstallments,
  mapStatusToIcon,
  mapMethodToValue,
  sumAmount,
};
