import {Button as MuiButton, ButtonProps} from "@mui/material";
import React, {ReactNode} from "react";
import {Colors} from "../colors";

export type ButtonTypes =
  | "contained-primary"
  | "contained-secondary"
  | "contained-error"
  | "outlined-primary"
  | "outlined-secondary"
  | "text";

export type ButtonType = ButtonProps & {
  buttonType?: ButtonTypes;
  variant?: "contained" | "text" | "outlined";
  color?: "secondary" | "inherit" | "primary" | "success" | "error" | "info" | "warning" | undefined;
  onClick?: any;
  longWidth?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
};

const getVariantAndColor = (
  type: ButtonTypes | undefined
): {variant: ButtonProps["variant"]; color: ButtonProps["color"]} => {
  if (type) {
    if (type === "contained-primary") {
      return {variant: "contained", color: "primary"};
    }
    if (type === "contained-secondary") {
      return {variant: "contained", color: "secondary"};
    }
    if (type === "contained-error") {
      return {variant: "contained", color: "error"};
    }
    if (type === "outlined-primary") {
      return {variant: "outlined", color: "primary"};
    }
    if (type === "outlined-secondary") {
      return {variant: "outlined", color: "info"};
    }
    if (type === "text") {
      return {variant: "text", color: undefined};
    }
  }
  return {variant: "contained", color: "primary"};
};

const buttonProps = {
  height: {
    small: 36,
    medium: 44,
    large: 48,
  },
  padding: {
    small: "10px 14px",
    medium: "10px 18px",
    large: "12px 20px",
  },
  font: {
    small: "14px",
    medium: "16px",
    large: "16px",
  },
};

export const Button = ({
  size = "large",
  buttonType = "contained-primary",
  onClick,
  className = "Button",
  icon,
  longWidth = false,
  children,
  fullWidth = false,
  sx,
  ...props
}: ButtonType) => {
  const {variant, color} = getVariantAndColor(buttonType);
  return (
    <MuiButton
      className={className}
      variant={variant}
      size={size}
      color={color}
      onClick={onClick || null}
      fullWidth={fullWidth}
      sx={{
        textTransform: "none",
        fontFamily: (theme) => theme.typography.body2.fontFamily,
        fontSize: buttonProps.font[size],
        fontWeight: 500,
        borderRadius: 999,
        height: buttonProps.height[size],
        minWidth: buttonProps.height[size] * 2,
        padding: buttonProps.padding[size],
        whiteSpace: "nowrap",
        ...(longWidth && {width: "300px"}),
        ...(buttonType === "contained-secondary" && {color: Colors.primary._800}),
        ...sx,
      }}
      {...props}
    >
      {icon}
      {children}
    </MuiButton>
  );
};
