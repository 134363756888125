import {Controller, useForm} from "react-hook-form";
import {EMAIL_REGEX} from "../../constants/regex";
import {FormattedMessage, useIntl} from "react-intl";
import React, {FunctionComponent, useEffect} from "react";
import {useUser, useTask, useAmplitude} from "../../contexts";
import {useSnackbar} from "notistack";
import {useAddEvent} from "../../apis/requests/events/addEvent";
import {useGetContactDetailQuery} from "../../apis/requests/user/getConatctDetails";
import {Container, Text, Button, TextField} from "bounce-ui/consumer";
import {Pages} from "../../bi-events/enum";

export type ContactUsContentProps = {
  onClose?: () => void;
  fetchContact: boolean;
};

type FormValues = {
  email: string;
  message: string;
};

const ContactUsContent: FunctionComponent<ContactUsContentProps> = ({onClose, fetchContact}) => {
  const {pageClickEvent} = useAmplitude();
  const {control, handleSubmit, formState, reset, setValue} = useForm<FormValues>();
  const {isValid} = formState;
  const {isAgent} = useUser();
  const {getUserId} = useTask();
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();
  const addEventState = useAddEvent();
  const contactDetail = useGetContactDetailQuery({}, {enabled: fetchContact});

  useEffect(() => {
    if (contactDetail?.data) {
      setValue("email", contactDetail.data.email);
    }
  }, [contactDetail?.data?.email]);

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    try {
      const result = await addEventState.mutateAsync({
        eventData: {
          type: "CONTACT_US",
          user_id: isAgent ? getUserId() : undefined,
          account_id: undefined,
          data: {email: data.email, text: data.message},
        },
        isAgent: isAgent,
      });
      if (result) {
        enqueueSnackbar(<Text translateId={"messageSentSuccessfully"} size={14} />, {variant: "success"});
        reset();
      }
    } catch (e) {
    } finally {
      pageClickEvent({page: Pages.GET_HELP, cta: "send", data: {text: data.message}});
      onClose?.();
    }
  });

  return (
    <Container fullWidth sx={{gap: "20px"}}>
      <Controller
        name="email"
        rules={{
          required: intl.formatMessage({id: "requiredField"}),
          pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
        }}
        control={control}
        defaultValue=""
        render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
          return (
            <TextField
              {...field}
              inputRef={ref}
              fullWidth
              label={"email"}
              type="email"
              error={invalid}
              helperText={error?.message}
              inputProps={{
                maxLength: 50,
              }}
            />
          );
        }}
      />
      <Controller
        name="message"
        rules={{
          required: intl.formatMessage({id: "requiredField"}),
        }}
        control={control}
        defaultValue={""}
        render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
          return (
            <TextField
              {...field}
              placeholder={"Type Your Message Here"}
              inputRef={ref}
              variant={"outlined"}
              margin="normal"
              required
              fullWidth
              type="text"
              multiline
              rows={4}
              autoComplete="off"
              error={invalid}
              helperText={error?.message}
              InputLabelProps={{required: false}}
              inputProps={{
                style: {
                  height: "220px",
                },
                maxLength: 1024,
              }}
              sx={{margin: "0px"}}
            />
          );
        }}
      />
      <Button onClick={onSubmit} disabled={!isValid}>
        <FormattedMessage id="send" />
      </Button>
    </Container>
  );
};

export default ContactUsContent;
