import {Theme} from "@mui/material/styles";
import {Components} from "@mui/material/styles/components";

// ----------------------------------------------------------------------

export default function Radio(theme: Theme): Components {
  return {
    MuiRadio: {},
  };
}
