import {FormattedMessage} from "react-intl";
import React, {FunctionComponent, useCallback, useEffect} from "react";
import {Dialog, Link} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {CloseDialogButton, Container, Text, ImgAutoPay, Button, Colors} from "../../../../bounce-ui/consumer";
import {useAmplitude} from "../../../../contexts";
import {Pages} from "../../../../bi-events/enum";

export type EnableAutoPayDialogProps = {
  onEnableAutoPay?: () => void;
};

const EnableAutoPayDialog: FunctionComponent<EnableAutoPayDialogProps & NiceModalHocProps> = NiceModal.create(
  ({onEnableAutoPay}) => {
    const {pageViewEvent, pageClickEvent} = useAmplitude();
    const modal = useModal();

    const activateAutoPayHandler = useCallback(async (): Promise<void> => {
      pageClickEvent({page: Pages.SWITCH_TO_AUTOPAY, cta: "enable_autopay"});
      onEnableAutoPay?.();

      modal.resolve(true);
      modal.hide();
    }, [onEnableAutoPay]);

    const closeHandler = useCallback((): void => {
      modal.resolve(false);
      modal.hide();
    }, []);

    useEffect(() => {
      pageViewEvent({page: Pages.SWITCH_TO_AUTOPAY});
    }, []);

    return (
      <Dialog
        open={modal.visible}
        onClose={closeHandler}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CloseDialogButton onClose={closeHandler} />
        <Container sx={{gap: "25px", overflow: "auto"}}>
          <ImgAutoPay />
          <Container sx={{alignItems: "start", gap: "15px"}}>
            <Text translateId={"saveTimeWithAutoPay"} size={22} fontWeight={"bold"} />
            <Text translateId={"enableAutoPayPartOne"} />
            <Text translateId={"enableAutoPayPartTwo"} />
          </Container>
          <Container sx={{gap: "15px"}}>
            <Button onClick={activateAutoPayHandler}>
              <FormattedMessage id={"enableAutoPay"} />
            </Button>
            <Text size={14} translateId={"stayWithManualPayment"}>
              <Link onClick={closeHandler} sx={{textDecoration: "none"}}>
                <Text
                  size={14}
                  fontWeight={"bold"}
                  translateId={"manualPayment"}
                  sx={{color: Colors.primary._800, display: "inline", cursor: "pointer"}}
                />
              </Link>
              .
            </Text>
          </Container>
        </Container>
      </Dialog>
    );
  }
);

export default EnableAutoPayDialog;
