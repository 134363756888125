import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";

type Variables = {
  file_id?: string;
};

export const downloadFile = async (variables: Variables): Promise<Buffer> => {
  const result = await axiosInstance.get<Buffer, AxiosResponse<Buffer>>(
    `/backoffice/files/download/${variables.file_id}`
  );

  return result.data;
};
