import {axiosInstance} from "../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseMutationOptions, BaseUseMutationResult, useBaseMutation} from "../base/useBaseMutation";
import {toString} from "lodash";

type Variables = {
  account_id: string;
  user_id?: string;
  isAgent?: boolean;
  payment_plan_id: string;
};

type Data = string;

export const deactivatePaymentPlanForAccount = async (variables: Variables): Promise<Data> => {
  let result;
  if (variables.isAgent) {
    result = await axiosInstance.post<Data, AxiosResponse<Data>>(
      `/backoffice/deactivate-payment-plan/${variables.user_id}/${variables.payment_plan_id}`,
      {revoked_reason: "Agent deactivate payment plan"}
    );
  } else {
    result = await axiosInstance.post<Data, AxiosResponse<Data>>(
      `/frontend/payment_plans/deactivate-payment-plan/${variables.payment_plan_id}`,
      {revoked_reason: "User deactivate payment plan"}
    );
  }

  return toString(result.data);
};

export const useDeactivatePaymentPlanForAccount = (
  options?: BaseMutationOptions<Data, Variables>
): BaseUseMutationResult<Data, Variables> => {
  return useBaseMutation(deactivatePaymentPlanForAccount, {
    ...options,
    prefixKeysToInvalidate: (data, variables) => [["user"], ["account", variables.account_id]],
  });
};
