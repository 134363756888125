import React from "react";
import {OutlinedInput as MuiOutlinedInput, OutlinedInputProps} from "@mui/material";
export {InputAdornment} from "@mui/material";
export type OutlinedInputType = OutlinedInputProps & {};

export const OutlinedInput = ({sx, ...props}: OutlinedInputType) => {
  return (
    <MuiOutlinedInput
      sx={{
        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        ...sx,
      }}
      {...props}
    />
  );
};
