import {useIntl} from "react-intl";
import React, {FunctionComponent} from "react";
import {Controller, useForm} from "react-hook-form";
import {Dialog} from "@mui/material";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import FormValidations from "../../../../helpers/FormValidations";
import {CloseDialogButton, Container, LoadingButton, Text, TextField} from "../../../../bounce-ui/consumer";
import {EMAIL_REGEX} from "../../../../constants/regex";

interface IEditContactDialog {
  type: "tel" | "email";
  value: string;
  isLoading: boolean;
  onValueUpdated?: (value: string) => void;
}

type FormValues = {
  value: string;
};

const EditContactDialog: FunctionComponent<IEditContactDialog & NiceModalHocProps> = NiceModal.create(
  ({value, type, isLoading, onValueUpdated}) => {
    const modal = useModal();
    const intl = useIntl();

    const {control, handleSubmit, watch} = useForm<FormValues>();
    const currValue = watch("value");

    const onSubmit = handleSubmit((data): void => {
      onValueUpdated?.(data.value);

      modal.resolve(true);
      modal.hide();
    });

    const closeHandler = () => {
      modal.resolve(true);
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
      >
        <CloseDialogButton onClose={closeHandler} />
        <Container sx={{gap: "20px"}}>
          <Container sx={{alignItems: "start", width: "350px"}}>
            <Text translateId={"UpdateContactInfo"} size={22} fontWeight={"bold"} />
            <form onSubmit={onSubmit} noValidate style={{width: "100%"}}>
              <Controller
                name="value"
                rules={{
                  required: intl.formatMessage({id: "requiredField"}),
                  validate: (value) =>
                    type === "tel" && FormValidations.validateTelephone("", value)
                      ? intl.formatMessage({id: "phoneInvalid"})
                      : undefined,
                  ...(type === "email" && {
                    pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "emailInvalid"})},
                  }),
                }}
                control={control}
                defaultValue={value}
                render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      margin="normal"
                      required
                      fullWidth
                      label={type === "tel" ? "phoneNumber" : "email"}
                      type={type}
                      autoComplete="off"
                      autoFocus
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{required: false}}
                    />
                  );
                }}
              />
            </form>
          </Container>
          <LoadingButton
            id="update"
            disabled={!currValue || currValue === value}
            loading={isLoading}
            onClick={onSubmit}
          />
        </Container>
      </Dialog>
    );
  }
);

export default EditContactDialog;
